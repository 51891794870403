import React from "react";
import Card from "./Card";
import {Link} from 'react-router-dom';
import "./Card.css";
import ER1 from "../../components/assets/cards/1.png"
import ER2 from "../../components/assets/cards/2.png"
import ER3 from "../../components/assets/cards/3.png";
import ER4 from "../../components/assets/cards/4.png";
import ER5 from "../../components/assets/cards/5.png";
import ER6 from "../../components/assets/cards/6.png";
import ER7 from "../../components/assets/cards/7.png";
import ER8 from "../../components/assets/cards/8.png";
function Cards() {
  return (
    <div className="Game_Cards">
      <Link to='/DiamondMiner' ><Card text="Diamond Miner" image={ER1} /></Link> 
      <Link to='/RussianRoulette' ><Card text="Russian Roulette" image={ER2} /></Link>
      <Link to='/FunRoulette' ><Card text="Fun Roulette" image={ER3} /></Link>       
      <Link to='/SpinWheel' ><Card text="Spin Wheel" image={ER6} /></Link>
      <Card text="Roulette Spin" image={ER4} />
      <Card text="Ligntining Roulette" image={ER5} />
      <Card text="Timer Roulette" image={ER7} />
      <Card text="Coming Soon .." image={ER8} />
    </div>
  );
}

export default Cards;
