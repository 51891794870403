import React,{useContext} from 'react'
import Wheeltry from './Wheel'
import SpinWheelContext from './SpinWheelContext'
import wheelimg from './img/sWWbg.png'
import WLpopup from './../../components/Helper/WLpopup';
import Preset from './../../components/Helper/Preset';
function Gamepad() {
  const {betAmtExecded,handleBetAmtExecded,betAmount, gameOver, winAmt,resetGame} = useContext(SpinWheelContext);

  return (
    <div className='SWGamingPad flex-set'>
      <div className='SWSquareBg flex-set'  style={{ backgroundImage:`url(${wheelimg})`}}>
        <Wheeltry />
      </div>
      {betAmtExecded === true ? <WLpopup popopen={true} func={handleBetAmtExecded} text1={`Bet Amount Exceeded`} text2={`Please reset your bets`} text3={`Reset`} time={500} /> : null}
      {gameOver === true ? <> <WLpopup popopen={true} win={true} func={resetGame} text1={`Spin Wheel Multiplier ${ (winAmt/betAmount).toFixed(1)} `} text2={`You have won ${winAmt} `} text3={`${winAmt}`} time={1000} /><Preset preset="fireworks"/> </>: null }

      {/* if gameover output pad */}
    </div>
  )
}

export default Gamepad