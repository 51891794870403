import React ,{useContext} from 'react'
import ArcadeButton from './ArcadeButton'
import UserContext from '../Login/UserContext'
import SpinWheelContext from './SpinWheelContext';
import Chip from './../../components/Helper/Chip';
import labelbg from './img/SWLable.png';
import './BetPad.css'

function BetPad() {
  const {user} = useContext(UserContext);
  const {betAmount,handlebetAmount} = useContext(SpinWheelContext);
  return (
    <div className='SWBettingPad flex-set' > 
    <div className="SWlableandbet flex-set" >
      <div className='SWbetdetails flex-even SWFcol'>
      <span>User Tokens</span>
      <label className='SWLables' style={{backgroundImage:`url(${labelbg})`}}> {user.tokens} </label>
      </div>
      <div className='SWbetdetails flex-even SWFcol'>
        <span>Bet Tokens</span>
        <label className='SWLables' style={{backgroundImage:`url(${labelbg})`}}>{betAmount}</label>
      </div>
      <div className="SWbetdetails flex-set" style={{flexDirection:'column'}}>
      <div className='SWchipsetdiv '>
        <div onClick={(e) => {handlebetAmount(1)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={1} /></div>
        <div onClick={(e) => {handlebetAmount(5)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={5} /></div>
        <div onClick={(e) => {handlebetAmount(10)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={10} /></div>
        <div onClick={(e) => {handlebetAmount(50)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={50} /></div>
        <div onClick={(e) => {handlebetAmount(100)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={100} /></div>
        
      </div>
      <div className='SWchipsetdiv'>
        <div onClick={(e) => {handlebetAmount(500)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={500} /></div>
        <div onClick={(e) => {handlebetAmount(1000)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={1000} /></div>
        <div onClick={(e) => {handlebetAmount(2000)} } className={'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={2000} /></div>
        <div onClick={(e) => {handlebetAmount(5000)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={5000} /></div>
        <div onClick={(e) => {handlebetAmount(10000)} } className={ 'chipselectordiv flex-set'} ><Chip size={{w:"35px",h:"35px"}} value={10000} /></div>  
      </div>
    </div>

      </div>
      
      <ArcadeButton text="Spin" />
    </div>
  )
}

export default BetPad