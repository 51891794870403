import React from 'react'
import './Chip.css'

function Chip({size, value}) {

  const chipColor = (value) => {
    if (value >= 1 && value < 5) {
      return 'ivory'
    }
    else if (value >= 5 && value < 10) {
      return 'red'
    }
    else if (value >= 10 && value < 50) {
      return 'blue'
    }
    else if (value >= 50 && value < 100) {
      return 'orangered'
    }
    else if (value >= 100 && value < 500) {
      return 'black'
    }
    else if (value >= 500 && value <1000 ) {
      return 'purple'
    }
    else if (value >= 1000 && value < 2000) {
      return 'blue'
    }
    else if (value >= 2000 && value < 5000) {
      return 'orangered'
    }
    else if (value >= 5000 && value < 10000) {
      return 'black'
    }
    else if (value >= 10000 && value < 20000) {
      return 'black'
    }
    else if (value >= 20000 && value < 50000) {
      return 'black'
    }
    else if (value >= 50000 && value < 100000) {
      return 'black'
    }

  }

  return (
        <div className="centercircle flex-set" style={{width:size.w, height:size.h, backgroundColor:chipColor(value) , borderColor:`${value < 5 ? "black":"white"}`}} >
            <span style={{userSelect:'none' , cursor:"pointer", fontSize:'0.8rem',color:`${value <5 ? 'black' : "white"}`,fontWeight:'bolder'}}>{value}</span>
        </div>
  )
}

export default Chip