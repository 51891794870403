import React, {useContext} from 'react'
import './TokenDisplay.css'
import FunRouletteContext from './Fun_Roulette_Context'

function TokenDisplay() {
  const {user,totBetAmount} = useContext(FunRouletteContext)
  return (
    <div className='TokenDisplayFR'>
      <div className='TokenDivsFR'><span>{user.tokens}</span>
      </div>
      <div className='TokenDivsFR'><span>{totBetAmount}</span>
      </div>
    </div>
  )
}

export default TokenDisplay