import { createContext,useContext, useEffect } from "react";
import UserContext from "../Login/UserContext.jsx"
import { useState } from "react";
import axios from "axios";
// import { v4 as uuidv4 } from "uuid";
const SpinWheelContext = createContext();

export const SpinWheelProvider = ({children}) =>{
    const url = "http://api.test.goldkings.in";
    // const url = "http://localhost:3000";
    const { user,fetchUserTokens } = useContext(UserContext);
    const [betAmtExecded  ,setBetAmtExecded] = useState(false);
    const [gameOver, setGameOver] = useState(false);
    const [spinValue,setSpinValue] = useState(-1);
    const [winAmt,setWinAmt ] = useState(-1);
    const [betAmount,setBetAmount] = useState(0)
    const [mustSpin, setMustSpin] = useState(false);
    const [prizeNumber, setPrizeNumber] = useState(0);

    const resetGame = () =>{
        setBetAmtExecded(false);
        setGameOver(false)
        setSpinValue(-1)
        setWinAmt(-1)
        setBetAmount(0)
        setMustSpin(false)
        setPrizeNumber(0)
        fetchUserTokens();
    }

    const handleBetAmtExecded = () => {
        setBetAmtExecded(!betAmtExecded);
      };  

    const handlebetAmount = (value) =>{
        if (value + betAmount  > user.tokens){
            setBetAmtExecded(true)
        }
        else{
            setBetAmount( value + betAmount )
        }  
    }
 
    
   // async function call to backend
    const getValue = async(userId)=>{
        try {
            const response = await axios.post(`${url}/spinwheel/createGame`, {
              userId:userId,
              betAmount:betAmount
            },{
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
          }}
            ,);
            // alert(`Game created successfully: ${response.data.val } , ${response.data.returnAmt}`);
            console.log("#############################",response.data.returnAmt,response.data.multiplier)
            setMustSpin(true);
            setPrizeNumber(response.data.multiplier);
            setWinAmt(response.data.returnAmt)
          } catch (error) {
            alert(`Error creating game: ${error.message}`);
          }
    }  

    // handle if backcall is not handled showing server error
    return (
        <SpinWheelContext.Provider 
        value={{betAmtExecded,gameOver,spinValue,winAmt,betAmount,handlebetAmount,
            mustSpin,setMustSpin,prizeNumber,setPrizeNumber,getValue,setGameOver,handleBetAmtExecded,resetGame
        }} >
            {children}
        </SpinWheelContext.Provider>
    );
};

export default SpinWheelContext;