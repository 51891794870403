import React, { useState,useRef, useEffect , useContext } from 'react';
import './SpinWheel.css';
import url from './img/roulettecenter.png'
import { motion, useAnimationFrame } from 'framer-motion';
import RussianRouletteContext from './RussianRouletteMainContext';

const easeOutCubic = (t) => Math.pow(t - 1, 3) + 1;

function SpinWheel() {
  // Data
  const { numDetailsArray, handleStartStop, spin,setSpin, winNumber} = useContext(RussianRouletteContext);
  const init = parseInt((300 * 0.8)   / 2)
  const [radius, setRadius] = useState(init); // Radius of the circle
  const [position, setPosition] = useState({ x: 0 , y: -init });
  const [startTime, setStartTime] = useState(null); // Track the start time of the spin
  const [spinToNumber, setSpinToNumber] = useState(0); // The exact angle for stopping
  const [wheelangle,setwheelangle] = useState(0);
  const angleinrad = Math.tan((2 * Math.PI) / 38) * radius;
  const percentx1 = ((radius - angleinrad) * 100) / (2 * radius);
  const duration = 10; // Duration in seconds
  useEffect(() => {
    if (spin) {
      setStartTime(Date.now()); // Start spinning when the button is clicked
    }
  }, [spin]);

  useEffect(()=>{
    let rotationindex;
      for (let index = 0; index < numDetailsArray.length; index++) {
        if (numDetailsArray[index].val === winNumber  ){
          rotationindex = index;
        } 
      }
      const targetAngle = ((2 * Math.PI / 37) * rotationindex) - (Math.PI/1.9) ; // Divide the circle into 37 parts
      console.log(rotationindex, targetAngle)
      setSpinToNumber(targetAngle);
  },[winNumber])

  useAnimationFrame((t) => {
    if (!spin || !startTime) return;
    // setwheel
    const elapsedTime = Date.now() - startTime; // Calculate the elapsed time
    const timeRatio = Math.min(elapsedTime / 10000, 1); // Normalize elapsed time to [0, 1] over 10 seconds
    const easedTime = easeOutCubic(timeRatio); // Apply easing to the time ratio
    const totalRotation = easedTime * (2 * Math.PI * 10); // Total rotation over 10 seconds
    let angle;
    if (winNumber){
       angle = totalRotation + spinToNumber ;
    }
    else{
       angle = totalRotation;
    }

    const x = radius * Math.cos(angle); // Circular motion along X
    const y = radius * Math.sin(angle); // Circular motion along Y
    setPosition({ x, y });
    setwheelangle(wheelangle - 1.5)
    
    if (elapsedTime / 1000 > duration) {
      setSpin(false)
      handleStartStop();
    }
  });

 

  return (
    <motion.div  id='spinwheelRR' className="spinwheeldesign" 
      style={{
        border:'2px solid black',
        transform: `perspective(15cm) rotateX(45deg) rotateZ(${wheelangle}deg)`,
      }}
    >
      <div className="goldenborder"></div>
      <div className="spinwheel-container">
        {numDetailsArray.map((item, index) => {
          return (
            <div
              key={index.val}
              className="spinwheel"
              style={{
                clipPath: `polygon(50% 50%, 50% 0%, ${percentx1}% 0%)`,
                backgroundColor:`${item.color}`,
                transform: `translate(-50%, -50%) rotate(${9.74 * index}deg)`,
              }}
            >
              <span
                className="spinwheelnum"
                style={{
                  left: `${item.val < 10 ? '45%' : '43%'}`,
                }}
              >
                {item.val}
              </span>
            </div>
          );
        })}
      </div>

      <motion.div
        style={{
          zIndex: 1,
          width: 20,
          height: 20,
          borderRadius: '50%',
          backgroundColor: 'white',
          boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
          position: 'absolute',
          transform: `translate(${position.x}px, ${position.y}px)`,
        }}
      />
      <div className="spincenter2"></div>
      <div className="spincenter" style={{ backgroundImage:`url(${url})` }}>
      

      </div>

    </motion.div>
  );
}

export default SpinWheel;


// animate={ spin === false ? {perspective: "15cm", rotateX: "45deg",  rotateZ: "none" } : { 
      //   rotateZ: [0,-360 ],
      //   // perspective: "20cm", 
      //   // rotateX: "45deg",
      //   transition: { repeat: Infinity, duration: 10,  ease: 'linear' },
      // }}
      // animate={ spin === false ? { transform: `perspective("15cm") rotateX("45deg")` } : {       
      //  transform: `perspective("15cm") rotateX("45deg") rotateZ(${wheelangle})`,
      //   transition: { repeat: Infinity, duration: 10,  ease: 'linear' },
      // }}