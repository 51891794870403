import React,{useContext} from 'react'
import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
function RowSmallSplits() {
    const Array1 = [3,6,9,12,15,18,21,24,27,30,33,36];
    const Array1color = ["red","black", "red","red","black","red","red","black","red","red","black","red"];
    const {handleSmallRowBetSelector,DuoQuadbetBcColorHandler,betsmallRow1, betsmallRow2, betsmallRow3, betsmallRow4, betsmallRow5, betsmallRow6, betsmallRow7, betsmallRow8, betsmallRow9, betsmallRow10, betsmallRow11, betsmallRow12} = useContext(FunRouletteContext);
    const betsmallrowarray = [betsmallRow1, betsmallRow2, betsmallRow3, betsmallRow4, betsmallRow5, betsmallRow6, betsmallRow7, betsmallRow8, betsmallRow9, betsmallRow10, betsmallRow11, betsmallRow12];
    
  return (
    <>
    {
     Array1.map((val, index) => (
             < >
             <div  className={DuoQuadbetBcColorHandler(val)}
              ><div className="betDuoLinevertical"></div></div>
             <div 
             className="singleBetter flex-set" 
             onClick ={() => {handleSmallRowBetSelector(index+1)}}
             style={{ backgroundColor: Array1color[index] }}>
              { betsmallrowarray[index] ? <Chip size={{w:"25px",h:"25px"}} value={betsmallrowarray[index]} /> : null}  
             </div>          
             </> 
            ))
    }       
    </>
  )
}

export default RowSmallSplits