import React from 'react';
import Cards from './Cards';
import { useContext } from 'react'
import  UserContext  from '../Login/UserContext'
import { BrowserRouter as Router, Route, Routes,Link } from 'react-router-dom';
import DiamondMinerMain from '../Diamond_Miner/Diamond_Miner_Main';
import RussianRouletteMain  from '../RussianRoulette/RussianRouletteMain';
import SpinWheelMain from '../SpinWheel/SpinWheelMain';
import FunRouletteMain  from '../Fun_Roulette/Fun_Roulette_Main'
// import Lightining_Roulette_Main  from '../Lightining_Roulette/Lightining_Roulette_Main'
// import Roulette_Mini_T_Min  from '../Roulette_Mini_Timer/Roulette_Mini_T_Min'
// import Roulette_Spin  from '../Roulette_Spin/Roulette_Spin'
import Button from './../../components/Helper/Button';
import Loginform from '../Login/Loginform';
import './Main.css';

const Main = () => {
    const {user , handleLogout} = useContext(UserContext);
    return (
        <Router> 
        <div className='App-Main'>
        <Routes>
        <Route path="/" element={  user ? <Cards /> : <Loginform/> }  />
        <Route path="/DiamondMiner" element={<DiamondMinerMain/>} />
        <Route path="/RussianRoulette" element={<RussianRouletteMain />} />
        <Route path="/FunRoulette" element={<FunRouletteMain />} />
        <Route path="/SpinWheel" element={<SpinWheelMain />} />
        {/* <Route path="/Fun Roulette" element={<Fun_Roulette_Main />} />
        <Route path="/Lightining Roulette" element={<Lightining_Roulette_Main />} />
        <Route path="/Roulette Mini Timer" element={<Roulette_Mini_T_Min />} />
        <Route path="/Roulette Spin" element={<Roulette_Spin />} />  */}
        </Routes>    
        <Link to='/' ><Button text="X" func={handleLogout} color="red" width="5%" addClass={"closebtn"} /></Link>
        </div>
        </Router> 
    );
};

export default Main;