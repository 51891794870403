import React from 'react'
import './Square.css'
import Diamond from './img/2.png'
import Bomb from './img/1.png'
import diamondSound from './sound/1.mp3'
import bombSound from './sound/2.wav'
import {useState, useEffect,useContext} from 'react'
import WLpopup from '../../components/Helper/WLpopup.jsx';
import UserContext from "../Login/UserContext.jsx";
import  DiamondMinerContext  from "./DiamondMinerContext.jsx";

function playSound(sound) {
  const audio = new Audio(sound)
  audio.play()
}


function Square({colid,rowid,value,}) {

const [Image, setImage] = useState(undefined)
const [isClicked, setIsClicked] = useState(false)
const { user } = useContext(UserContext);
const { setAlert,  gameId, score, setScore, setLoss, setGameOver, gameOver, updateGame, win, loss, getBombLocation } = useContext(DiamondMinerContext);
useEffect(() => {
  if (score === 0) {
    setIsClicked(false)
  }
}, [score])

useEffect(() => {
    if (gameOver) {
      setTimeout(() => {
      if (value === '💎') {
        setImage(Diamond)
      }
      if (value === '💣') {
        setImage(Bomb)
      }
    }, 500
  )
    }
    else{
      setImage(undefined)
    }
    
  }, [gameOver, value]);
  
  async function clickHandler() {
    // console.log('clicked', (rowid*5)+colid, userId, gameId);
    if (gameId){
       const bombLocation = await getBombLocation((rowid*5)+colid, user.userId,gameId );
      console.log('bombLocation:', bombLocation);
      if (gameOver) {
        return
      }
      if ( bombLocation === "💎") {
        playSound(diamondSound)
        setImage(Diamond)
        setIsClicked(true)
        setScore(score+1)
        
      }
      if (bombLocation === '💣') {
        playSound(bombSound)
        setImage(Bomb)
        setGameOver(true)
        setLoss(true)
        setIsClicked(true)
        updateGame(user.userId,gameId,true,win,true)
      }


    }
    else{
      // alert("Please Place the Bet first using Bet button.")
      setAlert(true)
    }
    // else{
    //   if (gameOver) {
    //     return
    //   }
    //   if ( value === '💎') {
    //     playSound(diamondSound)
    //     setImage(Diamond)
    //     setIsClicked(true)
    //     setScore(score+1)
        
    //   } else if (value === '💣') {
    //     playSound(bombSound)
    //     setImage(Bomb)
    //     setGameOver(true)
    //     setLoss(true)
    //     setIsClicked(true)
    //   }
    // }
    
  }
  return (
    <div className='squareItem'  onClick={clickHandler} >
      {Image ? <img src={Image} className={ isClicked ? "squareClickedimg":"squareItemimg"} alt='' height={90} width={90}/> : <></>}
    </div>
  )
}

export default Square