import React,{useContext} from 'react'
import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
function RowMidSplits2() {
    const Array1 = [3,6,9,12,15,18,21,24,27,30,33,36];
    const Array2color = ["black","red","black","black","red","black","black","red","black","black","red","black"];
    const Array3color = ["red","black","red","black","black","red","red","black","red","black","black","red"];
    const {betduo1_2, betduo4_5, betduo7_8, betduo10_11, betduo13_14, betduo16_17, betduo19_20, betduo22_23, betduo25_26, betduo28_29, betduo31_32, betduo34_35,
      bettrio0_1_2,betquad1_2_4_5,betquad4_5_7_8,betquad7_8_10_11,betquad10_11_13_14,betquad13_14_16_17,betquad16_17_19_20,betquad19_20_22_23,betquad22_23_25_26,betquad25_26_28_29,betquad28_29_31_32,betquad31_32_34_35,
      handleDuoSideBetSelector1_2_34_35,handleQuadBetSelector1_2_34_35} = useContext(FunRouletteContext);
    const betsideduoarray1_2_34_35 = [betduo1_2, betduo4_5, betduo7_8, betduo10_11, betduo13_14, betduo16_17, betduo19_20, betduo22_23, betduo25_26, betduo28_29, betduo31_32, betduo34_35];
    const betquadarr1_2_4_7__31_32_34_35  = [bettrio0_1_2,betquad1_2_4_5,betquad4_5_7_8,betquad7_8_10_11,betquad10_11_13_14,betquad13_14_16_17,betquad16_17_19_20,betquad19_20_22_23,betquad22_23_25_26,betquad25_26_28_29,betquad28_29_31_32,betquad31_32_34_35];
 
  return (
    <>
    {Array1.map((val, index) => (
              <>
              <div
              onClick={()=>{ handleQuadBetSelector1_2_34_35(index)}}
               className="duobettervertical flex-set" >
                <div className="duoquadbetterside flex-set" style={{ background: `linear-gradient(to bottom, ${ val - 3 + 1 > 0 ?  Array2color[index-1] : 'rgba(0,0,0,0)'} 0% , ${ val - 3 + 1 > 0 ?  Array2color[index-1] : 'rgba(0,0,0,0)'} 50%, ${ val - 3  > 0 ?  Array3color[index-1] : 'rgba(0,0,0,0)'} 50% ,${val - 3  > 0 ?  Array3color[index-1] : 'rgba(0,0,0,0)'} 100%)`}}>
                  {val-3 > 0 ? <div className="duoquadhorizantal"></div> : null}
                </div>
                <div className="betDuoLinevertical"></div>
                <div className="duoquadbetterside flex-set" style={{ background: `linear-gradient(to bottom, ${Array2color[index]} 0% , ${Array2color[index]} 50%, ${Array3color[index]} 50% ,${Array3color[index]} 100%)`}} >
                {val > 0 ? <div className="duoquadhorizantal"></div> : null}
                </div>
                { betquadarr1_2_4_7__31_32_34_35[index] ? <Chip size={{w:"25px",h:"25px"}} value={betquadarr1_2_4_7__31_32_34_35[index]} /> : null}
                 
                </div>
              <div 
              
              key={index} 
              onClick={()=>{ handleDuoSideBetSelector1_2_34_35(index+1)}}
              className="singleBetter flex-set" 
              style={{ background: `linear-gradient(to bottom, ${Array2color[index]} 0% , ${Array2color[index]} 50%, ${Array3color[index]} 50% ,${Array3color[index]} 100%)`}}// numDetailsArray.find(item => item.val === val ).color }}
              >
                <div className="duoquadhorizantal"></div>
                { betsideduoarray1_2_34_35[index] ? <Chip size={{w:"25px",h:"25px"}} value={betsideduoarray1_2_34_35[index]} /> : null}
              </div>
              </> 
             ))
            }
    </>
  )
}

export default RowMidSplits2