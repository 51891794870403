import React, { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import UserContext from "../Login/UserContext";
const RussianRouletteContext = createContext();


export const RussianRouletteProvider = ({ children }) => {
  const {user,fetchUserTokens} = useContext(UserContext);
  const url = "http://api.test.goldkings.in";
    // const url = "http://localhost:3000";
  const [numbers,setNumbers] = useState([]);
  const [spin,setSpin] = useState(false);
  const [gameOver, setGameOver] = useState(false);
  const [winAmount, setWinAmount] = useState(-1)
  const [winNumber, setWinNumber] = useState(-1);
  const [betAmtExecded  ,setBetAmtExecded] = useState(false);
  const [chipSelector, setChipSelector] = useState({ value: 0 });
  const [totbetAmount, setTotBetAmount] = useState(0);
  const [bet1to18, setBet1to18] = useState(0);
  const [bet19to36, setBet19to36] = useState(0);
  const [bet1to12, setBet1to12] = useState(0);
  const [bet13to24, setBet13to24] = useState(0);
  const [bet25to36, setBet25to36] = useState(0);
  const [beteven, setBetEven] = useState(0);
  const [betodd, setBetOdd] = useState(0);
  const [betred, setBetRed] = useState(0);
  const [betblack, setBetBlack] = useState(0);
  const [betfullRow1, setFullBetRow1] = useState(0);
  const [betfullRow2, setFullBetRow2] = useState(0);
  const [betfullRow3, setFullBetRow3] = useState(0);
  const [betsmallRow1, setSmallBetRow1] = useState(0);
  const [betsmallRow2, setSmallBetRow2] = useState(0);
  const [betsmallRow3, setSmallBetRow3] = useState(0);
  const [betsmallRow4, setSmallBetRow4] = useState(0);
  const [betsmallRow5, setSmallBetRow5] = useState(0);
  const [betsmallRow6, setSmallBetRow6] = useState(0);
  const [betsmallRow7, setSmallBetRow7] = useState(0);
  const [betsmallRow8, setSmallBetRow8] = useState(0);
  const [betsmallRow9, setSmallBetRow9] = useState(0);
  const [betsmallRow10, setSmallBetRow10] = useState(0);
  const [betsmallRow11, setSmallBetRow11] = useState(0);
  const [betsmallRow12, setSmallBetRow12] = useState(0);

  const [betduo1_4, setBetDuo1_4] = useState(0);
  const [betduo4_7, setBetDuo4_7] = useState(0);
  const [betduo7_10, setBetDuo7_10] = useState(0);
  const [betduo10_13, setBetDuo10_13] = useState(0);
  const [betduo13_16, setBetDuo13_16] = useState(0);
  const [betduo16_19, setBetDuo16_19] = useState(0);
  const [betduo19_22, setBetDuo19_22] = useState(0);
  const [betduo22_25, setBetDuo22_25] = useState(0);
  const [betduo25_28, setBetDuo25_28] = useState(0);
  const [betduo28_31, setBetDuo28_31] = useState(0);
  const [betduo31_34, setBetDuo31_34] = useState(0);
  const [betduo2_5, setBetDuo2_5] = useState(0);
  const [betduo5_8, setBetDuo5_8] = useState(0);
  const [betduo8_11, setBetDuo8_11] = useState(0);
  const [betduo11_14, setBetDuo11_14] = useState(0);
  const [betduo14_17, setBetDuo14_17] = useState(0);
  const [betduo17_20, setBetDuo17_20] = useState(0);
  const [betduo20_23, setBetDuo20_23] = useState(0);
  const [betduo23_26, setBetDuo23_26] = useState(0);
  const [betduo26_29, setBetDuo26_29] = useState(0);
  const [betduo29_32, setBetDuo29_32] = useState(0);
  const [betduo32_35, setBetDuo32_35] = useState(0);
  const [betduo3_6, setBetDuo3_6] = useState(0);
  const [betduo6_9, setBetDuo6_9] = useState(0);
  const [betduo9_12, setBetDuo9_12] = useState(0);
  const [betduo12_15, setBetDuo12_15] = useState(0);
  const [betduo15_18, setBetDuo15_18] = useState(0);
  const [betduo18_21, setBetDuo18_21] = useState(0);
  const [betduo21_24, setBetDuo21_24] = useState(0);
  const [betduo24_27, setBetDuo24_27] = useState(0);
  const [betduo27_30, setBetDuo27_30] = useState(0);
  const [betduo30_33, setBetDuo30_33] = useState(0);
  const [betduo33_36, setBetDuo33_36] = useState(0);
  
  const [betduo1_2, setBetDuo1_2] = useState(0);
  const [betduo2_3, setBetDuo2_3] = useState(0);
  const [betduo4_5, setBetDuo4_5] = useState(0);
  const [betduo5_6, setBetDuo5_6] = useState(0);
  const [betduo7_8, setBetDuo7_8] = useState(0);
  const [betduo8_9, setBetDuo8_9] = useState(0);
  const [betduo10_11, setBetDuo10_11] = useState(0);
  const [betduo11_12, setBetDuo11_12] = useState(0);
  const [betduo13_14, setBetDuo13_14] = useState(0);
  const [betduo14_15, setBetDuo14_15] = useState(0);
  const [betduo16_17, setBetDuo16_17] = useState(0);
  const [betduo17_18, setBetDuo17_18] = useState(0);
  const [betduo19_20, setBetDuo19_20] = useState(0);
  const [betduo20_21, setBetDuo20_21] = useState(0);
  const [betduo22_23, setBetDuo22_23] = useState(0);
  const [betduo23_24, setBetDuo23_24] = useState(0);
  const [betduo25_26, setBetDuo25_26] = useState(0);
  const [betduo26_27, setBetDuo26_27] = useState(0);
  const [betduo28_29, setBetDuo28_29] = useState(0);
  const [betduo29_30, setBetDuo29_30] = useState(0);
  const [betduo31_32, setBetDuo31_32] = useState(0);
  const [betduo32_33, setBetDuo32_33] = useState(0);
  const [betduo34_35, setBetDuo34_35] = useState(0);
  const [betduo35_36, setBetDuo35_36] = useState(0);

  const [betduo0_1, setBetDuo0_1] = useState(0);
  const [betduo0_2, setBetDuo0_2] = useState(0);
  const [betduo0_3, setBetDuo0_3] = useState(0);

  const [bettrio0_1_2, setBetTrio0_1_2] = useState(0);
  const [bettrio0_2_3, setBetTrio0_2_3] = useState(0);

  const [betsingle1, setBetSingle1] = useState(0);
  const [betsingle4, setBetSingle4] = useState(0);
  const [betsingle7, setBetSingle7] = useState(0);
  const [betsingle10, setBetSingle10] = useState(0);
  const [betsingle13, setBetSingle13] = useState(0);
  const [betsingle16, setBetSingle16] = useState(0);
  const [betsingle19, setBetSingle19] = useState(0);
  const [betsingle22, setBetSingle22] = useState(0);
  const [betsingle25, setBetSingle25] = useState(0);
  const [betsingle28, setBetSingle28] = useState(0);
  const [betsingle31, setBetSingle31] = useState(0);
  const [betsingle34, setBetSingle34] = useState(0);

  const [betsingle2, setBetSingle2] = useState(0);
  const [betsingle5, setBetSingle5] = useState(0);
  const [betsingle8, setBetSingle8] = useState(0);
  const [betsingle11, setBetSingle11] = useState(0);
  const [betsingle14, setBetSingle14] = useState(0);
  const [betsingle17, setBetSingle17] = useState(0);
  const [betsingle20, setBetSingle20] = useState(0);
  const [betsingle23, setBetSingle23] = useState(0);
  const [betsingle26, setBetSingle26] = useState(0);
  const [betsingle29, setBetSingle29] = useState(0);
  const [betsingle32, setBetSingle32] = useState(0);
  const [betsingle35, setBetSingle35] = useState(0);

  const [betsingle3, setBetSingle3] = useState(0);
  const [betsingle6, setBetSingle6] = useState(0);
  const [betsingle9, setBetSingle9] = useState(0);
  const [betsingle12, setBetSingle12] = useState(0);
  const [betsingle15, setBetSingle15] = useState(0);
  const [betsingle18, setBetSingle18] = useState(0);
  const [betsingle21, setBetSingle21] = useState(0);
  const [betsingle24, setBetSingle24] = useState(0);
  const [betsingle27, setBetSingle27] = useState(0);
  const [betsingle30, setBetSingle30] = useState(0);
  const [betsingle33, setBetSingle33] = useState(0);
  const [betsingle36, setBetSingle36] = useState(0);

  const [betsingle0, setBetSingle0] = useState(0);

  const [betquad1_2_4_5, setBetQuad1_2_4_5] = useState(0);
  const [betquad2_3_5_6, setBetQuad2_3_5_6] = useState(0);
  const [betquad4_5_7_8, setBetQuad4_5_7_8] = useState(0);
  const [betquad5_6_8_9, setBetQuad5_6_8_9] = useState(0);
  const [betquad7_8_10_11, setBetQuad7_8_10_11] = useState(0);
  const [betquad8_9_11_12, setBetQuad8_9_11_12] = useState(0);
  const [betquad10_11_13_14, setBetQuad10_11_13_14] = useState(0);
  const [betquad11_12_14_15, setBetQuad11_12_14_15] = useState(0);
  const [betquad13_14_16_17, setBetQuad13_14_16_17] = useState(0);
  const [betquad14_15_17_18, setBetQuad14_15_17_18] = useState(0);
  const [betquad16_17_19_20, setBetQuad16_17_19_20] = useState(0);
  const [betquad17_18_20_21, setBetQuad17_18_20_21] = useState(0);
  const [betquad19_20_22_23, setBetQuad19_20_22_23] = useState(0);
  const [betquad20_21_23_24, setBetQuad20_21_23_24] = useState(0);
  const [betquad22_23_25_26, setBetQuad22_23_25_26] = useState(0);
  const [betquad23_24_26_27, setBetQuad23_24_26_27] = useState(0);
  const [betquad25_26_28_29, setBetQuad25_26_28_29] = useState(0);
  const [betquad26_27_29_30, setBetQuad26_27_29_30] = useState(0);
  const [betquad28_29_31_32, setBetQuad28_29_31_32] = useState(0);
  const [betquad29_30_32_33, setBetQuad29_30_32_33] = useState(0);
  const [betquad31_32_34_35, setBetQuad31_32_34_35] = useState(0);
  const [betquad32_33_35_36, setBetQuad32_33_35_36] = useState(0);


    const numDetailsArray = [
    { val: 0, color: "green" },
    { val: 32, color: "red" },
    { val: 15, color: "black" },
    { val: 19, color: "red" },
    { val: 4, color: "black" },
    { val: 21, color: "red" },
    { val: 2, color: "black" },
    { val: 25, color: "red" },
    { val: 17, color: "black" },
    { val: 34, color: "red" },
    { val: 6, color: "black" },
    { val: 27, color: "red" },
    { val: 13, color: "black" },
    { val: 36, color: "red" },
    { val: 11, color: "black" },
    { val: 30, color: "red" },
    { val: 8, color: "black" },
    { val: 23, color: "red" },
    { val: 10, color: "black" },
    { val: 5, color: "red" },
    { val: 24, color: "black" },
    { val: 16, color: "red" },
    { val: 33, color: "black" },
    { val: 1, color: "red" },
    { val: 20, color: "black" },
    { val: 14, color: "red" },
    { val: 31, color: "black" }, 
    { val: 9, color: "red" },
    { val: 22, color: "black" },
    { val: 18, color: "red" },
    { val: 29, color: "black" },
    { val: 7, color: "red" },
    { val: 28, color: "black" },
    { val: 12, color: "red" },
    { val: 35, color: "black" },
    { val: 3, color: "red" },
    { val: 26, color: "black" },
  ];

  

  const resetAllBets = () => {  
    if (winNumber !== -1 ){
      if (numbers.length < 10){
        setNumbers(prev=>([...prev,winNumber]));
      }
      else{
        numbers.push(winNumber)
        setNumbers(numbers.slice( numbers.length-10,numbers.length))
      }
    }
    setTotBetAmount(0);
    setGameOver(false)
    setWinAmount(-1);
    setWinNumber(-1);
    setBet1to18(0);
    setBet19to36(0);
    setBet1to12(0);
    setBet13to24(0);
    setBet25to36(0);
    setBetEven(0);
    setBetOdd(0);
    setBetRed(0);
    setBetBlack(0);
    setFullBetRow1(0);
    setFullBetRow2(0);
    setFullBetRow3(0);
    setSmallBetRow1(0);
    setSmallBetRow2(0);
    setSmallBetRow3(0);
    setSmallBetRow4(0);
    setSmallBetRow5(0);
    setSmallBetRow6(0);
    setSmallBetRow7(0);
    setSmallBetRow8(0);
    setSmallBetRow9(0);
    setSmallBetRow10(0);
    setSmallBetRow11(0);
    setSmallBetRow12(0);
    setBetDuo1_4(0);
    setBetDuo4_7(0);
    setBetDuo7_10(0);
    setBetDuo10_13(0);
    setBetDuo13_16(0);
    setBetDuo16_19(0);
    setBetDuo19_22(0);
    setBetDuo22_25(0);
    setBetDuo25_28(0);
    setBetDuo28_31(0);
    setBetDuo31_34(0);
    setBetDuo2_5(0);
    setBetDuo5_8(0);
    setBetDuo8_11(0);
    setBetDuo11_14(0);
    setBetDuo14_17(0);
    setBetDuo17_20(0);
    setBetDuo20_23(0);
    setBetDuo23_26(0);
    setBetDuo26_29(0);
    setBetDuo29_32(0);
    setBetDuo32_35(0);
    setBetDuo3_6(0);
    setBetDuo6_9(0);
    setBetDuo9_12(0);
    setBetDuo12_15(0);
    setBetDuo15_18(0);
    setBetDuo18_21(0);
    setBetDuo21_24(0);
    setBetDuo24_27(0);
    setBetDuo27_30(0);
    setBetDuo30_33(0);
    setBetDuo33_36(0);
    setBetDuo1_2(0);
    setBetDuo2_3(0);
    setBetDuo4_5(0);
    setBetDuo5_6(0);
    setBetDuo7_8(0);
    setBetDuo8_9(0);
    setBetDuo10_11(0);
    setBetDuo11_12(0);
    setBetDuo13_14(0);
    setBetDuo14_15(0);
    setBetDuo16_17(0);
    setBetDuo17_18(0);
    setBetDuo19_20(0);
    setBetDuo20_21(0);
    setBetDuo22_23(0);
    setBetDuo23_24(0);
    setBetDuo25_26(0);
    setBetDuo26_27(0);
    setBetDuo28_29(0);
    setBetDuo29_30(0);
    setBetDuo31_32(0);
    setBetDuo32_33(0);
    setBetDuo34_35(0);
    setBetDuo35_36(0);
    setBetDuo0_1(0);
    setBetDuo0_2(0);
    setBetDuo0_3(0);
    setBetTrio0_1_2(0);
    setBetTrio0_2_3(0);
    setBetSingle1(0);
    setBetSingle4(0);
    setBetSingle7(0);
    setBetSingle10(0);
    setBetSingle13(0);  
    setBetSingle16(0);
    setBetSingle19(0);
    setBetSingle22(0);
    setBetSingle25(0);
    setBetSingle28(0);
    setBetSingle31(0);
    setBetSingle34(0);
    setBetSingle2(0);
    setBetSingle5(0);
    setBetSingle8(0);
    setBetSingle11(0);
    setBetSingle14(0);
    setBetSingle17(0);
    setBetSingle20(0);
    setBetSingle23(0);
    setBetSingle26(0);
    setBetSingle29(0);
    setBetSingle32(0);
    setBetSingle35(0);
    setBetSingle3(0);
    setBetSingle6(0);
    setBetSingle9(0);
    setBetSingle12(0);
    setBetSingle15(0);
    setBetSingle18(0);
    setBetSingle21(0);
    setBetSingle24(0);
    setBetSingle27(0);
    setBetSingle30(0);
    setBetSingle33(0);
    setBetSingle36(0);
    setBetSingle0(0);
    setBetQuad1_2_4_5(0);
    setBetQuad2_3_5_6(0);
    setBetQuad4_5_7_8(0);
    setBetQuad5_6_8_9(0);
    setBetQuad7_8_10_11(0);
    setBetQuad8_9_11_12(0);
    setBetQuad10_11_13_14(0);
    setBetQuad11_12_14_15(0);
    setBetQuad13_14_16_17(0);
    setBetQuad14_15_17_18(0);
    setBetQuad16_17_19_20(0);
    setBetQuad17_18_20_21(0);
    setBetQuad19_20_22_23(0);
    setBetQuad20_21_23_24(0);
    setBetQuad22_23_25_26(0);
    setBetQuad23_24_26_27(0);
    setBetQuad25_26_28_29(0);
    setBetQuad26_27_29_30(0);
    setBetQuad28_29_31_32(0);
    setBetQuad29_30_32_33(0);
    setBetQuad31_32_34_35(0);
    setBetQuad32_33_35_36(0);
    fetchUserTokens();
  };

  const handleStartStop = () => {
    setTimeout(()=>{
      setGameOver(true)
    },100)
  };

  const handleBetAmtExecded = () => {
    setBetAmtExecded(!betAmtExecded);
  };  


  const setBets = async(userId) =>{
      try {
        const response = await axios.post(`${url}/russianRoulette/createGame`, {
          userId,
          totbetAmount,
          bet1to18,
          bet19to36,
          bet1to12,
          bet13to24,
          bet25to36,
          beteven,
          betodd,
          betred,
          betblack,
          betfullRow1,
          betfullRow2,
          betfullRow3,
          betsmallRow1,
          betsmallRow2,
          betsmallRow3,
          betsmallRow4,
          betsmallRow5,
          betsmallRow6,
          betsmallRow7,
          betsmallRow8,
          betsmallRow9,
          betsmallRow10,
          betsmallRow11,
          betsmallRow12,
          betduo1_4,
          betduo4_7,
          betduo7_10,
          betduo10_13,
          betduo13_16,
          betduo16_19,
          betduo19_22,
          betduo22_25,
          betduo25_28,
          betduo28_31,
          betduo31_34,
          betduo2_5,
          betduo5_8,
          betduo8_11,
          betduo11_14,
          betduo14_17,
          betduo17_20,
          betduo20_23,
          betduo23_26,
          betduo26_29,
          betduo29_32,
          betduo32_35,
          betduo3_6,
          betduo6_9,
          betduo9_12,
          betduo12_15,
          betduo15_18,
          betduo18_21,
          betduo21_24,
          betduo24_27,
          betduo27_30,
          betduo30_33,
          betduo33_36,
          betduo1_2,
          betduo2_3,
          betduo4_5,
          betduo5_6,
          betduo7_8,
          betduo8_9,
          betduo10_11,
          betduo11_12,
          betduo13_14,
          betduo14_15,
          betduo16_17,
          betduo17_18,
          betduo19_20,
          betduo20_21,
          betduo22_23,
          betduo23_24,
          betduo25_26,
          betduo26_27,
          betduo28_29,
          betduo29_30,
          betduo31_32,
          betduo32_33,
          betduo34_35,
          betduo35_36,
          betduo0_1,
          betduo0_2,
          betduo0_3,
          bettrio0_1_2,
          bettrio0_2_3,
          betsingle1,
          betsingle4,
          betsingle7,
          betsingle10,
          betsingle13,
          betsingle16,
          betsingle19,
          betsingle22,
          betsingle25,
          betsingle28,
          betsingle31,
          betsingle34,
          betsingle2,
          betsingle5,
          betsingle8,
          betsingle11,
          betsingle14,
          betsingle17,
          betsingle20,
          betsingle23,
          betsingle26,
          betsingle29,
          betsingle32,
          betsingle35,
          betsingle3,
          betsingle6,
          betsingle9,
          betsingle12,
          betsingle15,
          betsingle18,
          betsingle21,
          betsingle24,
          betsingle27,
          betsingle30,
          betsingle33,
          betsingle36,
          betsingle0,
          betquad1_2_4_5,
          betquad2_3_5_6,
          betquad4_5_7_8,
          betquad5_6_8_9,
          betquad7_8_10_11,
          betquad8_9_11_12,
          betquad10_11_13_14,
          betquad11_12_14_15,
          betquad13_14_16_17,
          betquad14_15_17_18,
          betquad16_17_19_20,
          betquad17_18_20_21,
          betquad19_20_22_23,
          betquad20_21_23_24,
          betquad22_23_25_26,
          betquad23_24_26_27,
          betquad25_26_28_29,
          betquad26_27_29_30,
          betquad28_29_31_32,
          betquad29_30_32_33,
          betquad31_32_34_35,
          betquad32_33_35_36
        },{
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
      }}
        ,);
        // alert(`Game created successfully: ${response.data.val } , ${response.data.returnAmt}`);
        console.log("#############################",response.data.returnAmt,response.data.val)
        setSpin(true)
        setWinAmount(response.data.returnAmt)
        setWinNumber(response.data.val)
        
      } catch (error) {
        alert(`Error creating game: ${error.message}`);
      }
  }
  

  const handlechipSelector = (value) => {
    setChipSelector({ value });
  };
  const handleSmallRowBetSelector = (value) => {
    switch (value) {
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow1(betsmallRow1 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow2(betsmallRow2 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow3(betsmallRow3 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow4(betsmallRow4 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow5(betsmallRow5 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow6(betsmallRow6 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow7(betsmallRow7 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow8(betsmallRow8 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow9(betsmallRow9 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow10(betsmallRow10 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow11(betsmallRow11 + chipSelector.value);
        break;
      case 12:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setSmallBetRow12(betsmallRow12 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleDuoBetSelector0_34 = (value) => {
    switch (value) {
      case 0:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo0_1(betduo0_1 + chipSelector.value);
        break;
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo1_4(betduo1_4 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) :  setBetDuo4_7(betduo4_7 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) :  setBetDuo7_10(betduo7_10 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) :  setBetDuo10_13(betduo10_13 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo13_16(betduo13_16 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo16_19(betduo16_19 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo19_22(betduo19_22 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo22_25(betduo22_25 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo25_28(betduo25_28 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo28_31(betduo28_31 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo31_34(betduo31_34 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleDuoBetSelector0_35 = (value) => { 
    switch (value) {
      case 0:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo0_2(betduo0_2 + chipSelector.value);
        break;
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo2_5(betduo2_5 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo5_8(betduo5_8 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo8_11(betduo8_11 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo11_14(betduo11_14 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo14_17(betduo14_17 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo17_20(betduo17_20 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo20_23(betduo20_23 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo23_26(betduo23_26 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo26_29(betduo26_29 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo29_32(betduo29_32 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo32_35(betduo32_35 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleDuoBetSelector0_36 = (value) => {
    switch (value) {
      case 0:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo0_3(betduo0_3 + chipSelector.value);
        break;
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo3_6(betduo3_6 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) :  setBetDuo6_9(betduo6_9 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo9_12(betduo9_12 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo12_15(betduo12_15 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo15_18(betduo15_18 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo18_21(betduo18_21 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo21_24(betduo21_24 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo24_27(betduo24_27 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo27_30(betduo27_30 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo30_33(betduo30_33 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo33_36(betduo33_36 + chipSelector.value);
        break;
      default:
        break;
    }
  };


  const handlesingleBetSelector1_34 = (value) => {
    switch (value) {
      case 1:
       totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle1(betsingle1 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle4(betsingle4 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle7(betsingle7 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle10(betsingle10 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle13(betsingle13 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle16(betsingle16 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle19(betsingle19 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) :  setBetSingle22(betsingle22 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle25(betsingle25 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle28(betsingle28 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle31(betsingle31 + chipSelector.value);
        break;
      case 12:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle34(betsingle34 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handlesingleBetSelector2_35 = (value) => {
    switch (value) {
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle2(betsingle2 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle5(betsingle5 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle8(betsingle8 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle11(betsingle11 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle14(betsingle14 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle17(betsingle17 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle20(betsingle20 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle23(betsingle23 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle26(betsingle26 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle29(betsingle29 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle32(betsingle32 + chipSelector.value);
        break;
      case 12:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle35(betsingle35 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handlesingleBetSelector3_36 = (value) => {
    switch (value) {
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle3(betsingle3 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle6(betsingle6 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle9(betsingle9 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle12(betsingle12 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle15(betsingle15 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle18(betsingle18 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle21(betsingle21 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle24(betsingle24 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle27(betsingle27 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle30(betsingle30 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle33(betsingle33 + chipSelector.value);
        break;
      case 12:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle36(betsingle36 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleDuoSideBetSelector1_2_34_35 = (value) => {
    switch (value) {
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo1_2(betduo1_2 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo4_5(betduo4_5 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo7_8(betduo7_8 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo10_11(betduo10_11 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo13_14(betduo13_14 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo16_17(betduo16_17 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo19_20(betduo19_20 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo22_23(betduo22_23 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo25_26(betduo25_26 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo28_29(betduo28_29 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo31_32(betduo31_32 + chipSelector.value);
        break;
      case 12:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo34_35(betduo34_35 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleDuoSideBetSelector2_3_35_36 = (value) => {
    switch (value) {
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo2_3(betduo2_3 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo5_6(betduo5_6 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo8_9(betduo8_9 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo11_12(betduo11_12 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo14_15(betduo14_15 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo17_18(betduo17_18 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo20_21(betduo20_21 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo23_24(betduo23_24 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo26_27(betduo26_27 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo29_30(betduo29_30 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo32_33(betduo32_33 + chipSelector.value);
        break;
      case 12:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetDuo35_36(betduo35_36 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleQuadBetSelector1_2_34_35 = (value) => {
    switch (value) {
      case 0:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetTrio0_1_2(bettrio0_1_2 + chipSelector.value);
        break;
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad1_2_4_5(betquad1_2_4_5 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad4_5_7_8(betquad4_5_7_8 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad7_8_10_11(betquad7_8_10_11 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad10_11_13_14(betquad10_11_13_14 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad13_14_16_17(betquad13_14_16_17 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad16_17_19_20(betquad16_17_19_20 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad19_20_22_23(betquad19_20_22_23 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad22_23_25_26(betquad22_23_25_26 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad25_26_28_29(betquad25_26_28_29 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad28_29_31_32(betquad28_29_31_32 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad31_32_34_35(betquad31_32_34_35 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  const handleQuadBetSelector2_3_35_36 = (value) => {
    switch (value) {
      case 0 :
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetTrio0_2_3(bettrio0_2_3 + chipSelector.value);
        break;
      case 1:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad2_3_5_6(betquad2_3_5_6 + chipSelector.value);
        break;
      case 2:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad5_6_8_9(betquad5_6_8_9 + chipSelector.value);
        break;
      case 3:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad8_9_11_12(betquad8_9_11_12 + chipSelector.value);
        break;
      case 4:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad11_12_14_15(betquad11_12_14_15 + chipSelector.value);
        break;
      case 5:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad14_15_17_18(betquad14_15_17_18 + chipSelector.value);
        break;
      case 6:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad17_18_20_21(betquad17_18_20_21 + chipSelector.value);
        break;
      case 7:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad20_21_23_24(betquad20_21_23_24 + chipSelector.value);
        break;
      case 8:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad23_24_26_27(betquad23_24_26_27 + chipSelector.value);
        break;
      case 9:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad26_27_29_30(betquad26_27_29_30 + chipSelector.value);
        break;
      case 10:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad29_30_32_33(betquad29_30_32_33 + chipSelector.value);
        break;
      case 11:
        totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetQuad32_33_35_36(betquad32_33_35_36 + chipSelector.value);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setTotBetAmount(
      bet1to18 +
      bet19to36 +
      bet1to12 +
      bet13to24 +
      bet25to36 +
      beteven +
      betodd +
      betred +
      betblack +
      betfullRow1 +
      betfullRow2 +
      betfullRow3 +
      betsmallRow1 +
      betsmallRow2 +
      betsmallRow3 +
      betsmallRow4 +
      betsmallRow5 +
      betsmallRow6 +
      betsmallRow7 +
      betsmallRow8 +
      betsmallRow9 +
      betsmallRow10 +
      betsmallRow11 +
      betsmallRow12 +
      betduo1_4 +
      betduo4_7 +
      betduo7_10 +
      betduo10_13 +
      betduo13_16 +
      betduo16_19 +
      betduo19_22 +
      betduo22_25 +
      betduo25_28 +
      betduo28_31 +
      betduo31_34 +
      betduo1_2 +
      betduo2_3 +
      betduo4_5 +
      betduo5_6 +
      betduo7_8 +
      betduo8_9 +
      betduo10_11 +
      betduo11_12 +
      betduo13_14 +
      betduo14_15 +
      betduo16_17 +
      betduo17_18 +
      betduo19_20 +
      betduo20_21 +
      betduo22_23 +
      betduo23_24 +
      betduo25_26 +
      betduo26_27 +
      betduo28_29 +
      betduo29_30 +
      betduo31_32 +
      betduo32_33 +
      betduo34_35 +
      betduo35_36 +
      betquad1_2_4_5 +
      betquad2_3_5_6 +
      betquad4_5_7_8 +
      betquad5_6_8_9 +
      betquad7_8_10_11 +
      betquad8_9_11_12 +
      betquad10_11_13_14 +
      betquad11_12_14_15 +
      betquad13_14_16_17 +
      betquad14_15_17_18 +
      betquad16_17_19_20 +
      betquad17_18_20_21 +
      betquad19_20_22_23 +
      betquad20_21_23_24 +
      betquad22_23_25_26 +
      betquad23_24_26_27 +
      betquad25_26_28_29 +
      betquad26_27_29_30 +
      betquad28_29_31_32 +
      betquad29_30_32_33 +
      betquad31_32_34_35 +
      betquad32_33_35_36 +
      betsingle1 +
      betsingle4 +
      betsingle7 +
      betsingle10 +
      betsingle13 +
      betsingle16 +
      betsingle19 +
      betsingle22 +
      betsingle25 +
      betsingle28 +
      betsingle31 +
      betsingle34 +
      betsingle2 +
      betsingle5 +
      betsingle8 +
      betsingle11 +
      betsingle14 +
      betsingle17 +
      betsingle20 +
      betsingle23 +
      betsingle26 +
      betsingle29 +
      betsingle32 +
      betsingle35 +
      betsingle3 +
      betsingle6 +
      betsingle9 +
      betsingle12 +
      betsingle15 +
      betsingle18 +
      betsingle21 +
      betsingle24 +
      betsingle27 +
      betsingle30 +
      betsingle33 +
      betsingle36 +
      betsingle0 +
      betduo0_1 +
      betduo0_2 +
      betduo0_3 +
      bettrio0_1_2 +
      bettrio0_2_3
    );
  }, [
    bet1to18,
    bet19to36,
    bet1to12,
    bet13to24,
    bet25to36,
    beteven,
    betodd,
    betred,
    betblack,
    betfullRow1,
    betfullRow2,
    betfullRow3,
    betsmallRow1,
    betsmallRow2,
    betsmallRow3,
    betsmallRow4,
    betsmallRow5,
    betsmallRow6,
    betsmallRow7,
    betsmallRow8,
    betsmallRow9,
    betsmallRow10,
    betsmallRow11,
    betsmallRow12,
    betduo1_4,
    betduo4_7,
    betduo7_10,
    betduo10_13,
    betduo13_16,
    betduo16_19,
    betduo19_22,
    betduo22_25,
    betduo25_28,
    betduo28_31,
    betduo31_34,
    betduo1_2,
    betduo2_3,
    betduo4_5,
    betduo5_6,
    betduo7_8,
    betduo8_9,
    betduo10_11,
    betduo11_12,
    betduo13_14,
    betduo14_15,
    betduo16_17,
    betduo17_18,
    betduo19_20,
    betduo20_21,
    betduo22_23,
    betduo23_24,
    betduo25_26,
    betduo26_27,
    betduo28_29,
    betduo29_30,
    betduo31_32,
    betduo32_33,
    betduo34_35,
    betduo35_36,
    betquad1_2_4_5,
    betquad2_3_5_6,
    betquad4_5_7_8,
    betquad5_6_8_9,
    betquad7_8_10_11,
    betquad8_9_11_12,
    betquad10_11_13_14,
    betquad11_12_14_15,
    betquad13_14_16_17,
    betquad14_15_17_18,
    betquad16_17_19_20,
    betquad17_18_20_21,
    betquad19_20_22_23,
    betquad20_21_23_24,
    betquad22_23_25_26,
    betquad23_24_26_27,
    betquad25_26_28_29,
    betquad26_27_29_30,
    betquad28_29_31_32,
    betquad29_30_32_33,
    betquad31_32_34_35,
    betquad32_33_35_36,
    betsingle1,
    betsingle4,
    betsingle7,
    betsingle10,
    betsingle13,
    betsingle16,
    betsingle19,
    betsingle22,
    betsingle25,
    betsingle28,
    betsingle31,
    betsingle34,
    betsingle2,
    betsingle5,
    betsingle8,
    betsingle11,
    betsingle14,
    betsingle17,
    betsingle20,
    betsingle23,
    betsingle26,
    betsingle29,
    betsingle32,
    betsingle35,
    betsingle3,
    betsingle6,
    betsingle9,
    betsingle12,
    betsingle15,
    betsingle18,
    betsingle21,
    betsingle24,
    betsingle27,
    betsingle30,
    betsingle33,
    betsingle36,
    betsingle0,
    betduo0_1,
    betduo0_2,
    betduo0_3,
    bettrio0_1_2,
    bettrio0_2_3,
  ]);

  return (
    <RussianRouletteContext.Provider
      value={{numbers,
        chipSelector,
        setChipSelector,
        totbetAmount,
        setTotBetAmount,
        handlechipSelector,
        bet1to18,
        setBet1to18,
        bet19to36,
        setBet19to36,
        beteven,
        setBetEven,
        betodd,
        setBetOdd,
        betred,
        setBetRed,
        betblack,
        setBetBlack,
        bet1to12,
        setBet1to12,
        bet13to24,
        setBet13to24,
        bet25to36,
        setBet25to36,
        betfullRow1,
        setFullBetRow1,
        betfullRow2,
        setFullBetRow2,
        betfullRow3,
        setFullBetRow3,
        handleSmallRowBetSelector,
        betsmallRow1,
        betsmallRow2,
        betsmallRow3,
        betsmallRow4,
        betsmallRow5,
        betsmallRow6,
        betsmallRow7,
        betsmallRow8,
        betsmallRow9,
        betsmallRow10,
        betsmallRow11,
        betsmallRow12,
        handleDuoBetSelector0_34,
        betduo0_1,
        betduo1_4,
        betduo4_7,
        betduo7_10,
        betduo10_13,
        betduo13_16,
        betduo16_19,
        betduo19_22,
        betduo22_25,
        betduo25_28,
        betduo28_31,
        betduo31_34,
        handlesingleBetSelector1_34,
        betsingle1,
        betsingle4,
        betsingle7,
        betsingle10,
        betsingle13,
        betsingle16,
        betsingle19,
        betsingle22,
        betsingle25,
        betsingle28,
        betsingle31,
        betsingle34,
        handleDuoBetSelector0_35,
        betduo0_2,
        betduo2_5,
        betduo5_8,
        betduo8_11,
        betduo11_14,
        betduo14_17,
        betduo17_20,
        betduo20_23,
        betduo23_26,
        betduo26_29,
        betduo29_32,
        betduo32_35,
        handlesingleBetSelector2_35,
        betsingle2,
        betsingle5,
        betsingle8,
        betsingle11,
        betsingle14,
        betsingle17,
        betsingle20,
        betsingle23,
        betsingle26,
        betsingle29,
        betsingle32,
        betsingle35,
        handleDuoBetSelector0_36,
        betduo0_3,
        betduo3_6,
        betduo6_9,
        betduo9_12,
        betduo12_15,
        betduo15_18,
        betduo18_21,
        betduo21_24,
        betduo24_27,
        betduo27_30,
        betduo30_33,
        betduo33_36,
        handlesingleBetSelector3_36,
        betsingle3,
        betsingle6,
        betsingle9,
        betsingle12,
        betsingle15,
        betsingle18,
        betsingle21,
        betsingle24,
        betsingle27,
        betsingle30,
        betsingle33,
        betsingle36,
        betsingle0,
        setBetSingle0,
        handleDuoSideBetSelector1_2_34_35,
        betduo1_2,
        betduo4_5,
        betduo7_8,
        betduo10_11,
        betduo13_14,
        betduo16_17,
        betduo19_20,
        betduo22_23,
        betduo25_26,
        betduo28_29,
        betduo31_32,
        betduo34_35,
        handleDuoSideBetSelector2_3_35_36,
        betduo2_3,
        betduo5_6,
        betduo8_9,
        betduo11_12,
        betduo14_15,
        betduo17_18,
        betduo20_21,
        betduo23_24,
        betduo26_27,
        betduo29_30,
        betduo32_33,
        betduo35_36,
        handleQuadBetSelector1_2_34_35,
        betquad1_2_4_5,
        betquad2_3_5_6,
        betquad4_5_7_8,
        betquad5_6_8_9,
        betquad7_8_10_11,
        betquad8_9_11_12,
        betquad10_11_13_14,
        betquad11_12_14_15,
        betquad13_14_16_17,
        betquad14_15_17_18,
        betquad16_17_19_20,
        betquad17_18_20_21,
        betquad19_20_22_23,
        betquad20_21_23_24,
        betquad22_23_25_26,
        betquad23_24_26_27,
        betquad25_26_28_29,
        betquad26_27_29_30,
        betquad28_29_31_32,
        betquad29_30_32_33,
        betquad31_32_34_35,
        betquad32_33_35_36,
        handleQuadBetSelector2_3_35_36,
        bettrio0_1_2,
        bettrio0_2_3,
        resetAllBets,
        setBets,
        numDetailsArray,
        handleStartStop,
        spin,setSpin,
        winNumber,
        winAmount,
        gameOver,
        user,
        betAmtExecded,setBetAmtExecded,handleBetAmtExecded,
      }}
    >
      {children}
    </RussianRouletteContext.Provider>
  );
};

export default RussianRouletteContext;
