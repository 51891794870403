import React from "react";
import "./RussianRoulette.css";
import { RussianRouletteProvider } from "./RussianRouletteMainContext.jsx";
import { Link } from "react-router-dom";
import Button from "../../components/Helper/Button";
import GamePad from "./GamePad.jsx";
import BettingPad from "./BettingPad.jsx";

const RussianRouletteMain = () => {
  return (
    <RussianRouletteProvider>
      <div className="RRmaindiv">
        <BettingPad />
        <GamePad />
        <Link to="/">
          <Button addClass={"buttonposition"} text="←" />
        </Link>
      </div>
    </RussianRouletteProvider>
  );
};

export default RussianRouletteMain;
