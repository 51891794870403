import React,{useContext} from 'react'
import './BetButtons.css'
import FunRouletteContext from './Fun_Roulette_Context'
import UserContext from '../Login/UserContext';
function BetButtons() {
  const {user} = useContext(UserContext)
  const {setBets,resetAllBets} = useContext(FunRouletteContext);
  return (
    <div className='BetButtonsDesignFR'>
      <div onClick={()=>{setBets(user.userId)}} className='BetButtons flex-set'><span>Spin</span></div>
      <div onClick={()=>{resetAllBets()}} className='BetButtons flex-set'><span>Clear</span></div>
    </div>
  )
}

export default BetButtons