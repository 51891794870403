import React from 'react'
import BetPad from './BetPad'
import Gamepad from './Gamepad'
import { SpinWheelProvider } from './SpinWheelContext'
import './SpinWheel.css'
import { Link } from "react-router-dom";
import Button from "../../components/Helper/Button";
function SpinWheelMain() {
  return (
    <SpinWheelProvider>
    <div className='spinwheelmain'>
      <div className="SpinWheelName">
        <span>Spin</span> <span>Wheel</span>
      </div>
      <BetPad />
      <Gamepad />
    </div>
    <Link to="/">
          <Button addClass={"buttonposition"} text='←' />
        </Link>
    </SpinWheelProvider>
  )
}

export default SpinWheelMain