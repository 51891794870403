// import React from 'react'
import React, { useState, useEffect ,useContext } from "react";
import { Wheel } from "react-custom-roulette";
import { v4 as uuidv4 } from "uuid";
import SpinWheelContext from "./SpinWheelContext";

const Roulette = () => {
    const {mustSpin,prizeNumber,setMustSpin,setGameOver} = useContext(SpinWheelContext)

    const [data, setData] = useState([
        {
            id: uuidv4(),
            option: "0.2"
        },
        {
            id: uuidv4(),
            option: "0.5"
        },
        {
            id: uuidv4(),
            option: "1"
        },
        {
            id: uuidv4(),
            option: "1.5"
        },
        {
            id: uuidv4(),
            option: "0.2"
        },
        {
            id: uuidv4(),
            option: "0.5"
        },
        {
            id: uuidv4(),
            option: "1"
        },
        {
            id: uuidv4(),
            option: "2"
        },
        {
            id: uuidv4(),
            option: "0.2"
        },
        {
            id: uuidv4(),
            option: "0.5"
        },
        {
            id: uuidv4(),
            option: "1.5"
        },
        {
            id: uuidv4(),
            option: "0.2"
        },
        {
            id: uuidv4(),
            option: "0.5",
        },
        {
            id: uuidv4(),
            option: "1"
        },
        {
            id: uuidv4(),
            option: "0.2"
        },
        {
            id: uuidv4(),
            option: "0.5"
        },
        {
            id: uuidv4(),
            option: "5"
        },
        {
            id: uuidv4(),
            option: "0.5"
        },

    ]);
  

  return (
    <>
      <div align="center" className="roulette-container">
        <Wheel
          mustStartSpinning={mustSpin}
        //   spinDuration={[0.5]}
          prizeNumber={prizeNumber}
          data={data}
          outerBorderColor={["goldenrod"]}
          outerBorderWidth={[10]}
          innerBorderColor={["#f2f2f2"]}
          radiusLineColor={["tranparent"]}
          radiusLineWidth={[1]}
          textColors={["#f5f5f5"]}
          textDistance={80}
          fontSize={[22]}
          perpendicularText={true}
          backgroundColors={[
            "#42f5e9",
            "#169ed8",
            "#175fa9",
            "#3f297e",
            "#a525fa",
            "#871f7f",
            "#be1180",
            "#e5177b",
            "#dc0936",
            "#e6471d",
            "#f7a416",
            "#efe61f",
            "#aefc1c",
            "#49c949",
            "#64b031",
            "#239b63",
            "#02b86c",
            "#02d983"

          ]}
          onStopSpinning={() => {
            setMustSpin(false);
            setGameOver(true)
          }}
        />
      </div>
    </>
  );
};

export default Roulette;
