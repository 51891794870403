import React, { createContext, useState,useContext,useEffect } from 'react';
import UserContext from '../Login/UserContext'; 
import axios from "axios";
import { set } from 'animejs';
const FunRouletteContext = createContext();

// Create a provider component
export const FunRouletteProvider = ({ children }) => {
    const url = "http://api.test.goldkings.in";
    // const url = "http://localhost:3000";
    const { user,fetchUserTokens } = useContext(UserContext);
    const [numbers,setNumbers] = useState([]);
    const [betAmtExecded  ,setBetAmtExecded] = useState(false);
    const [chipSelector, setChipSelector] = useState({ value: 0 });
    const [totBetAmount, setTotBetAmount] = useState(0);
    const [removeBet,setRemoveBet] = useState(false);
    const [frgameOver, setFRGameOver] = useState(false);
      const [winAmount, setWinAmount] = useState()
      const [winNumber, setWinNumber] = useState();

      const [bet1to18, setBet1to18] = useState(0);
      const [bet19to36, setBet19to36] = useState(0);
      const [bet1to12, setBet1to12] = useState(0);
      const [bet13to24, setBet13to24] = useState(0);
      const [bet25to36, setBet25to36] = useState(0);
      const [beteven, setBetEven] = useState(0);
      const [betodd, setBetOdd] = useState(0);
      const [betred, setBetRed] = useState(0);
      const [betblack, setBetBlack] = useState(0);

      const [betfullRow1, setFullBetRow1] = useState(0);
      const [betfullRow2, setFullBetRow2] = useState(0);
      const [betfullRow3, setFullBetRow3] = useState(0);

      const [betsmallRow1, setSmallBetRow1] = useState(0);
      const [betsmallRow2, setSmallBetRow2] = useState(0);
      const [betsmallRow3, setSmallBetRow3] = useState(0);
      const [betsmallRow4, setSmallBetRow4] = useState(0);
      const [betsmallRow5, setSmallBetRow5] = useState(0);
      const [betsmallRow6, setSmallBetRow6] = useState(0);
      const [betsmallRow7, setSmallBetRow7] = useState(0);
      const [betsmallRow8, setSmallBetRow8] = useState(0);
      const [betsmallRow9, setSmallBetRow9] = useState(0);
      const [betsmallRow10, setSmallBetRow10] = useState(0);
      const [betsmallRow11, setSmallBetRow11] = useState(0);
      const [betsmallRow12, setSmallBetRow12] = useState(0);
    
      const [betduo1_4, setBetDuo1_4] = useState(0);
      const [betduo4_7, setBetDuo4_7] = useState(0);
      const [betduo7_10, setBetDuo7_10] = useState(0);
      const [betduo10_13, setBetDuo10_13] = useState(0);
      const [betduo13_16, setBetDuo13_16] = useState(0);
      const [betduo16_19, setBetDuo16_19] = useState(0);
      const [betduo19_22, setBetDuo19_22] = useState(0);
      const [betduo22_25, setBetDuo22_25] = useState(0);
      const [betduo25_28, setBetDuo25_28] = useState(0);
      const [betduo28_31, setBetDuo28_31] = useState(0);
      const [betduo31_34, setBetDuo31_34] = useState(0);

      const [betduo2_5, setBetDuo2_5] = useState(0);
      const [betduo5_8, setBetDuo5_8] = useState(0);
      const [betduo8_11, setBetDuo8_11] = useState(0);
      const [betduo11_14, setBetDuo11_14] = useState(0);
      const [betduo14_17, setBetDuo14_17] = useState(0);
      const [betduo17_20, setBetDuo17_20] = useState(0);
      const [betduo20_23, setBetDuo20_23] = useState(0);
      const [betduo23_26, setBetDuo23_26] = useState(0);
      const [betduo26_29, setBetDuo26_29] = useState(0);
      const [betduo29_32, setBetDuo29_32] = useState(0);
      const [betduo32_35, setBetDuo32_35] = useState(0);

      const [betduo3_6, setBetDuo3_6] = useState(0);
      const [betduo6_9, setBetDuo6_9] = useState(0);
      const [betduo9_12, setBetDuo9_12] = useState(0);
      const [betduo12_15, setBetDuo12_15] = useState(0);
      const [betduo15_18, setBetDuo15_18] = useState(0);
      const [betduo18_21, setBetDuo18_21] = useState(0);
      const [betduo21_24, setBetDuo21_24] = useState(0);
      const [betduo24_27, setBetDuo24_27] = useState(0);
      const [betduo27_30, setBetDuo27_30] = useState(0);
      const [betduo30_33, setBetDuo30_33] = useState(0);
      const [betduo33_36, setBetDuo33_36] = useState(0);
      
      const [betduo1_2, setBetDuo1_2] = useState(0);
      const [betduo2_3, setBetDuo2_3] = useState(0);
      const [betduo4_5, setBetDuo4_5] = useState(0);
      const [betduo5_6, setBetDuo5_6] = useState(0);
      const [betduo7_8, setBetDuo7_8] = useState(0);
      const [betduo8_9, setBetDuo8_9] = useState(0);
      const [betduo10_11, setBetDuo10_11] = useState(0);
      const [betduo11_12, setBetDuo11_12] = useState(0);
      const [betduo13_14, setBetDuo13_14] = useState(0);
      const [betduo14_15, setBetDuo14_15] = useState(0);
      const [betduo16_17, setBetDuo16_17] = useState(0);
      const [betduo17_18, setBetDuo17_18] = useState(0);
      const [betduo19_20, setBetDuo19_20] = useState(0);
      const [betduo20_21, setBetDuo20_21] = useState(0);
      const [betduo22_23, setBetDuo22_23] = useState(0);
      const [betduo23_24, setBetDuo23_24] = useState(0);
      const [betduo25_26, setBetDuo25_26] = useState(0);
      const [betduo26_27, setBetDuo26_27] = useState(0);
      const [betduo28_29, setBetDuo28_29] = useState(0);
      const [betduo29_30, setBetDuo29_30] = useState(0);
      const [betduo31_32, setBetDuo31_32] = useState(0);
      const [betduo32_33, setBetDuo32_33] = useState(0);
      const [betduo34_35, setBetDuo34_35] = useState(0);
      const [betduo35_36, setBetDuo35_36] = useState(0);
    
      const [betduo0_1, setBetDuo0_1] = useState(0);
      const [betduo00_3, setBetDuo00_3] = useState(0);
    
      const [bettrio0_1_2, setBetTrio0_1_2] = useState(0);
      const [bettrio00_2_3, setBetTrio00_2_3] = useState(0);
      const [bettrio0_00_2, setBetTrio0_00_2] = useState(0);
    
      const [betsingle0, setBetSingle0] = useState(0);
      const [betsingle00, setBetSingle00] = useState(0);

      const [betsingle1, setBetSingle1] = useState(0);
      const [betsingle4, setBetSingle4] = useState(0);
      const [betsingle7, setBetSingle7] = useState(0);
      const [betsingle10, setBetSingle10] = useState(0);
      const [betsingle13, setBetSingle13] = useState(0);
      const [betsingle16, setBetSingle16] = useState(0);
      const [betsingle19, setBetSingle19] = useState(0);
      const [betsingle22, setBetSingle22] = useState(0);
      const [betsingle25, setBetSingle25] = useState(0);
      const [betsingle28, setBetSingle28] = useState(0);
      const [betsingle31, setBetSingle31] = useState(0);
      const [betsingle34, setBetSingle34] = useState(0);
    
      const [betsingle2, setBetSingle2] = useState(0);
      const [betsingle5, setBetSingle5] = useState(0);
      const [betsingle8, setBetSingle8] = useState(0);
      const [betsingle11, setBetSingle11] = useState(0);
      const [betsingle14, setBetSingle14] = useState(0);
      const [betsingle17, setBetSingle17] = useState(0);
      const [betsingle20, setBetSingle20] = useState(0);
      const [betsingle23, setBetSingle23] = useState(0);
      const [betsingle26, setBetSingle26] = useState(0);
      const [betsingle29, setBetSingle29] = useState(0);
      const [betsingle32, setBetSingle32] = useState(0);
      const [betsingle35, setBetSingle35] = useState(0);
    
      const [betsingle3, setBetSingle3] = useState(0);
      const [betsingle6, setBetSingle6] = useState(0);
      const [betsingle9, setBetSingle9] = useState(0);
      const [betsingle12, setBetSingle12] = useState(0);
      const [betsingle15, setBetSingle15] = useState(0);
      const [betsingle18, setBetSingle18] = useState(0);
      const [betsingle21, setBetSingle21] = useState(0);
      const [betsingle24, setBetSingle24] = useState(0);
      const [betsingle27, setBetSingle27] = useState(0);
      const [betsingle30, setBetSingle30] = useState(0);
      const [betsingle33, setBetSingle33] = useState(0);
      const [betsingle36, setBetSingle36] = useState(0);
    
      const [betquad1_2_4_5, setBetQuad1_2_4_5] = useState(0);
      const [betquad2_3_5_6, setBetQuad2_3_5_6] = useState(0);
      const [betquad4_5_7_8, setBetQuad4_5_7_8] = useState(0);
      const [betquad5_6_8_9, setBetQuad5_6_8_9] = useState(0);
      const [betquad7_8_10_11, setBetQuad7_8_10_11] = useState(0);
      const [betquad8_9_11_12, setBetQuad8_9_11_12] = useState(0);
      const [betquad10_11_13_14, setBetQuad10_11_13_14] = useState(0);
      const [betquad11_12_14_15, setBetQuad11_12_14_15] = useState(0);
      const [betquad13_14_16_17, setBetQuad13_14_16_17] = useState(0);
      const [betquad14_15_17_18, setBetQuad14_15_17_18] = useState(0);
      const [betquad16_17_19_20, setBetQuad16_17_19_20] = useState(0);
      const [betquad17_18_20_21, setBetQuad17_18_20_21] = useState(0);
      const [betquad19_20_22_23, setBetQuad19_20_22_23] = useState(0);
      const [betquad20_21_23_24, setBetQuad20_21_23_24] = useState(0);
      const [betquad22_23_25_26, setBetQuad22_23_25_26] = useState(0);
      const [betquad23_24_26_27, setBetQuad23_24_26_27] = useState(0);
      const [betquad25_26_28_29, setBetQuad25_26_28_29] = useState(0);
      const [betquad26_27_29_30, setBetQuad26_27_29_30] = useState(0);
      const [betquad28_29_31_32, setBetQuad28_29_31_32] = useState(0);
      const [betquad29_30_32_33, setBetQuad29_30_32_33] = useState(0);
      const [betquad31_32_34_35, setBetQuad31_32_34_35] = useState(0);
      const [betquad32_33_35_36, setBetQuad32_33_35_36] = useState(0);

      const numDetailsArray = [
        
        { val: 0, color: "green" },
        { val: 32, color: "red" },
        { val: 15, color: "black" },
        { val: 19, color: "red" },
        { val: 4, color: "black" },
        { val: 21, color: "red" },
        { val: 2, color: "black" },
        { val: 25, color: "red" },
        { val: 17, color: "black" },
        { val: 34, color: "red" },
        { val: 6, color: "black" },
        { val: 27, color: "red" },
        { val: 13, color: "black" },
        { val: 36, color: "red" },
        { val: 11, color: "black" },
        { val: 30, color: "red" },
        { val: 8, color: "black" },
        { val: 23, color: "red" },
        { val: 10, color: "black" },
        { val: 5, color: "red" },
        { val: 24, color: "black" },
        { val: "00" ,color:"green"},
        { val: 16, color: "red" },
        { val: 33, color: "black" },
        { val: 1, color: "red" },
        { val: 20, color: "black" },
        { val: 14, color: "red" },
        { val: 31, color: "black" }, 
        { val: 9, color: "red" },
        { val: 22, color: "black" },
        { val: 18, color: "red" },
        { val: 29, color: "black" },
        { val: 7, color: "red" },
        { val: 28, color: "black" },
        { val: 12, color: "red" },
        { val: 35, color: "black" },
        { val: 3, color: "red" },
        { val: 26, color: "black" },
      ];

      const resetAllBets = () => {  
        setNumbers(prev=>([...prev,winNumber]));
        setTotBetAmount(0);
        setBetAmtExecded(false);
        setRemoveBet(false);
        setFRGameOver(false)
        setWinAmount();
        setWinNumber();
        setBet1to18(0);
        setBet19to36(0);
        setBet1to12(0);
        setBet13to24(0);
        setBet25to36(0);
        setBetEven(0);
        setBetOdd(0);
        setBetRed(0);
        setBetBlack(0);
        setFullBetRow1(0);
        setFullBetRow2(0);
        setFullBetRow3(0);
        setSmallBetRow1(0);
        setSmallBetRow2(0);
        setSmallBetRow3(0);
        setSmallBetRow4(0);
        setSmallBetRow5(0);
        setSmallBetRow6(0);
        setSmallBetRow7(0);
        setSmallBetRow8(0);
        setSmallBetRow9(0);
        setSmallBetRow10(0);
        setSmallBetRow11(0);
        setSmallBetRow12(0);
        setBetDuo1_4(0);
        setBetDuo4_7(0);
        setBetDuo7_10(0);
        setBetDuo10_13(0);
        setBetDuo13_16(0);
        setBetDuo16_19(0);
        setBetDuo19_22(0);
        setBetDuo22_25(0);
        setBetDuo25_28(0);
        setBetDuo28_31(0);
        setBetDuo31_34(0);
        setBetDuo2_5(0);
        setBetDuo5_8(0);
        setBetDuo8_11(0);
        setBetDuo11_14(0);
        setBetDuo14_17(0);
        setBetDuo17_20(0);
        setBetDuo20_23(0);
        setBetDuo23_26(0);
        setBetDuo26_29(0);
        setBetDuo29_32(0);
        setBetDuo32_35(0);
        setBetDuo3_6(0);
        setBetDuo6_9(0);
        setBetDuo9_12(0);
        setBetDuo12_15(0);
        setBetDuo15_18(0);
        setBetDuo18_21(0);
        setBetDuo21_24(0);
        setBetDuo24_27(0);
        setBetDuo27_30(0);
        setBetDuo30_33(0);
        setBetDuo33_36(0);
        setBetDuo1_2(0);
        setBetDuo2_3(0);
        setBetDuo4_5(0);
        setBetDuo5_6(0);
        setBetDuo7_8(0);
        setBetDuo8_9(0);
        setBetDuo10_11(0);
        setBetDuo11_12(0);
        setBetDuo13_14(0);
        setBetDuo14_15(0);
        setBetDuo16_17(0);
        setBetDuo17_18(0);
        setBetDuo19_20(0);
        setBetDuo20_21(0);
        setBetDuo22_23(0);
        setBetDuo23_24(0);
        setBetDuo25_26(0);
        setBetDuo26_27(0);
        setBetDuo28_29(0);
        setBetDuo29_30(0);
        setBetDuo31_32(0);
        setBetDuo32_33(0);
        setBetDuo34_35(0);
        setBetDuo35_36(0);
        setBetDuo0_1(0);
        setBetDuo00_3(0);
        setBetTrio0_1_2(0);
        setBetTrio00_2_3(0);
        setBetTrio0_00_2(0);
        setBetSingle1(0);
        setBetSingle4(0);
        setBetSingle7(0);
        setBetSingle10(0);
        setBetSingle13(0);  
        setBetSingle16(0);
        setBetSingle19(0);
        setBetSingle22(0);
        setBetSingle25(0);
        setBetSingle28(0);
        setBetSingle31(0);
        setBetSingle34(0);
        setBetSingle2(0);
        setBetSingle5(0);
        setBetSingle8(0);
        setBetSingle11(0);
        setBetSingle14(0);
        setBetSingle17(0);
        setBetSingle20(0);
        setBetSingle23(0);
        setBetSingle26(0);
        setBetSingle29(0);
        setBetSingle32(0);
        setBetSingle35(0);
        setBetSingle3(0);
        setBetSingle6(0);
        setBetSingle9(0);
        setBetSingle12(0);
        setBetSingle15(0);
        setBetSingle18(0);
        setBetSingle21(0);
        setBetSingle24(0);
        setBetSingle27(0);
        setBetSingle30(0);
        setBetSingle33(0);
        setBetSingle36(0);
        setBetSingle0(0);
        setBetSingle00(0);
        setBetQuad1_2_4_5(0);
        setBetQuad2_3_5_6(0);
        setBetQuad4_5_7_8(0);
        setBetQuad5_6_8_9(0);
        setBetQuad7_8_10_11(0);
        setBetQuad8_9_11_12(0);
        setBetQuad10_11_13_14(0);
        setBetQuad11_12_14_15(0);
        setBetQuad13_14_16_17(0);
        setBetQuad14_15_17_18(0);
        setBetQuad16_17_19_20(0);
        setBetQuad17_18_20_21(0);
        setBetQuad19_20_22_23(0);
        setBetQuad20_21_23_24(0);
        setBetQuad22_23_25_26(0);
        setBetQuad23_24_26_27(0);
        setBetQuad25_26_28_29(0);
        setBetQuad26_27_29_30(0);
        setBetQuad28_29_31_32(0);
        setBetQuad29_30_32_33(0);
        setBetQuad31_32_34_35(0);
        setBetQuad32_33_35_36(0);
        fetchUserTokens();
      };

      const DuoQuadbetBcColorHandler = (num) => {
        var numClass = "duobettervertical flex-set ";
        var numcolor = numDetailsArray.find(item => item.val === num).color;
        if (num < 4){
          if (numcolor === "red"){
            numClass += "duoverticalgradientred"
          }
          else{
            numClass += "duoverticalgradientblack"
          }
        }
        else{
          var numprevcolor = numDetailsArray.find(item => item.val === num-3).color;
          if (numcolor === numprevcolor){
            if (numcolor === "red"){
              numClass += "duoverticalgradientRR"
            }
            else{
              numClass += "duoverticalgradientBB"
            }
          }else{
            if (numcolor === "red"){
              numClass += "duoverticalgradientBR"
            }
            else{
              numClass += "duoverticalgradientRB"
            }
          }
        }
        // console.log(numcolor, num,num -3, numClass);
        return numClass;
      }

   const handlechipSelector = (value) => {
        setChipSelector({ value: value });
    };

    const handleSmallRowBetSelector = (value) => {
      switch (value) {
      case 1:
        removeBet ? setSmallBetRow1(0) : setSmallBetRow1(betsmallRow1 + chipSelector.value);
        break;
      case 2:
        removeBet ? setSmallBetRow2(0) : setSmallBetRow2(betsmallRow2 + chipSelector.value);
        break;
      case 3:
        removeBet ? setSmallBetRow3(0) : setSmallBetRow3(betsmallRow3 + chipSelector.value);
        break;
      case 4:
        removeBet ? setSmallBetRow4(0) : setSmallBetRow4(betsmallRow4 + chipSelector.value);
        break;
      case 5:
        removeBet ? setSmallBetRow5(0) : setSmallBetRow5(betsmallRow5 + chipSelector.value);
        break;
      case 6:
        removeBet ? setSmallBetRow6(0) : setSmallBetRow6(betsmallRow6 + chipSelector.value);
        break;
      case 7:
        removeBet ? setSmallBetRow7(0) : setSmallBetRow7(betsmallRow7 + chipSelector.value);
        break;
      case 8:
        removeBet ? setSmallBetRow8(0) : setSmallBetRow8(betsmallRow8 + chipSelector.value);
        break;
      case 9:
        removeBet ? setSmallBetRow9(0) : setSmallBetRow9(betsmallRow9 + chipSelector.value);
        break;
      case 10:
        removeBet ? setSmallBetRow10(0) : setSmallBetRow10(betsmallRow10 + chipSelector.value);
        break;
      case 11:
        removeBet ? setSmallBetRow11(0) : setSmallBetRow11(betsmallRow11 + chipSelector.value);
        break;
      case 12:
        removeBet ? setSmallBetRow12(0) : setSmallBetRow12(betsmallRow12 + chipSelector.value);
        break;
      default:
        break;
      }
    };
  
    const handleDuoBetSelector0_34 = (value) => {
      switch (value) {
      case 0:
        removeBet ? setBetDuo0_1(0) : setBetDuo0_1(betduo0_1 + chipSelector.value);
        break;
      case 1:
        removeBet ? setBetDuo1_4(0) : setBetDuo1_4(betduo1_4 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetDuo4_7(0) : setBetDuo4_7(betduo4_7 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetDuo7_10(0) : setBetDuo7_10(betduo7_10 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetDuo10_13(0) : setBetDuo10_13(betduo10_13 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetDuo13_16(0) : setBetDuo13_16(betduo13_16 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetDuo16_19(0) : setBetDuo16_19(betduo16_19 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetDuo19_22(0) : setBetDuo19_22(betduo19_22 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetDuo22_25(0) : setBetDuo22_25(betduo22_25 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetDuo25_28(0) : setBetDuo25_28(betduo25_28 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetDuo28_31(0) : setBetDuo28_31(betduo28_31 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetDuo31_34(0) : setBetDuo31_34(betduo31_34 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handleDuoBetSelector00_35 = (value) => { 
      switch (value) {
      case 0:
        removeBet ? setBetTrio0_00_2(0) : setBetTrio0_00_2(bettrio0_00_2 + chipSelector.value);
        break;
      case 1:
        removeBet ? setBetDuo2_5(0) : setBetDuo2_5(betduo2_5 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetDuo5_8(0) : setBetDuo5_8(betduo5_8 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetDuo8_11(0) : setBetDuo8_11(betduo8_11 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetDuo11_14(0) : setBetDuo11_14(betduo11_14 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetDuo14_17(0) : setBetDuo14_17(betduo14_17 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetDuo17_20(0) : setBetDuo17_20(betduo17_20 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetDuo20_23(0) : setBetDuo20_23(betduo20_23 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetDuo23_26(0) : setBetDuo23_26(betduo23_26 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetDuo26_29(0) : setBetDuo26_29(betduo26_29 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetDuo29_32(0) : setBetDuo29_32(betduo29_32 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetDuo32_35(0) : setBetDuo32_35(betduo32_35 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handleDuoBetSelector00_36 = (value) => {
      switch (value) {
      case 0:
        removeBet ? setBetDuo00_3(0) : setBetDuo00_3(betduo00_3 + chipSelector.value);
        break;
      case 1:
        removeBet ? setBetDuo3_6(0) : setBetDuo3_6(betduo3_6 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetDuo6_9(0) : setBetDuo6_9(betduo6_9 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetDuo9_12(0) : setBetDuo9_12(betduo9_12 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetDuo12_15(0) : setBetDuo12_15(betduo12_15 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetDuo15_18(0) : setBetDuo15_18(betduo15_18 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetDuo18_21(0) : setBetDuo18_21(betduo18_21 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetDuo21_24(0) : setBetDuo21_24(betduo21_24 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetDuo24_27(0) : setBetDuo24_27(betduo24_27 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetDuo27_30(0) : setBetDuo27_30(betduo27_30 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetDuo30_33(0) : setBetDuo30_33(betduo30_33 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetDuo33_36(0) : setBetDuo33_36(betduo33_36 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handlesingleBetSelector1_34 = (value) => {
      switch (value) {
      case 1:
        removeBet ? setBetSingle1(0) : setBetSingle1(betsingle1 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetSingle4(0) : setBetSingle4(betsingle4 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetSingle7(0) : setBetSingle7(betsingle7 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetSingle10(0) : setBetSingle10(betsingle10 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetSingle13(0) : setBetSingle13(betsingle13 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetSingle16(0) : setBetSingle16(betsingle16 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetSingle19(0) : setBetSingle19(betsingle19 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetSingle22(0) : setBetSingle22(betsingle22 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetSingle25(0) : setBetSingle25(betsingle25 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetSingle28(0) : setBetSingle28(betsingle28 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetSingle31(0) : setBetSingle31(betsingle31 + chipSelector.value);
        break;
      case 12:
        removeBet ? setBetSingle34(0) : setBetSingle34(betsingle34 + chipSelector.value);
        break;
      default:
        break;
      }
    };
  
    const handlesingleBetSelector2_35 = (value) => {
      switch (value) {
      case 1:
        removeBet ? setBetSingle2(0) : setBetSingle2(betsingle2 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetSingle5(0) : setBetSingle5(betsingle5 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetSingle8(0) : setBetSingle8(betsingle8 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetSingle11(0) : setBetSingle11(betsingle11 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetSingle14(0) : setBetSingle14(betsingle14 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetSingle17(0) : setBetSingle17(betsingle17 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetSingle20(0) : setBetSingle20(betsingle20 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetSingle23(0) : setBetSingle23(betsingle23 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetSingle26(0) : setBetSingle26(betsingle26 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetSingle29(0) : setBetSingle29(betsingle29 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetSingle32(0) : setBetSingle32(betsingle32 + chipSelector.value);
        break;
      case 12:
        removeBet ? setBetSingle35(0) : setBetSingle35(betsingle35 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handlesingleBetSelector3_36 = (value) => {
      switch (value) {
      case 1:
        removeBet ? setBetSingle3(0) : setBetSingle3(betsingle3 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetSingle6(0) : setBetSingle6(betsingle6 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetSingle9(0) : setBetSingle9(betsingle9 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetSingle12(0) : setBetSingle12(betsingle12 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetSingle15(0) : setBetSingle15(betsingle15 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetSingle18(0) : setBetSingle18(betsingle18 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetSingle21(0) : setBetSingle21(betsingle21 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetSingle24(0) : setBetSingle24(betsingle24 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetSingle27(0) : setBetSingle27(betsingle27 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetSingle30(0) : setBetSingle30(betsingle30 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetSingle33(0) : setBetSingle33(betsingle33 + chipSelector.value);
        break;
      case 12:
        removeBet ? setBetSingle36(0) : setBetSingle36(betsingle36 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handleDuoSideBetSelector1_2_34_35 = (value) => {
      switch (value) {
      case 1:
        removeBet ? setBetDuo1_2(0) : setBetDuo1_2(betduo1_2 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetDuo4_5(0) : setBetDuo4_5(betduo4_5 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetDuo7_8(0) : setBetDuo7_8(betduo7_8 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetDuo10_11(0) : setBetDuo10_11(betduo10_11 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetDuo13_14(0) : setBetDuo13_14(betduo13_14 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetDuo16_17(0) : setBetDuo16_17(betduo16_17 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetDuo19_20(0) : setBetDuo19_20(betduo19_20 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetDuo22_23(0) : setBetDuo22_23(betduo22_23 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetDuo25_26(0) : setBetDuo25_26(betduo25_26 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetDuo28_29(0) : setBetDuo28_29(betduo28_29 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetDuo31_32(0) : setBetDuo31_32(betduo31_32 + chipSelector.value);
        break;
      case 12:
        removeBet ? setBetDuo34_35(0) : setBetDuo34_35(betduo34_35 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handleDuoSideBetSelector2_3_35_36 = (value) => {
      switch (value) {
      case 1:
        removeBet ? setBetDuo2_3(0) : setBetDuo2_3(betduo2_3 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetDuo5_6(0) : setBetDuo5_6(betduo5_6 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetDuo8_9(0) : setBetDuo8_9(betduo8_9 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetDuo11_12(0) : setBetDuo11_12(betduo11_12 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetDuo14_15(0) : setBetDuo14_15(betduo14_15 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetDuo17_18(0) : setBetDuo17_18(betduo17_18 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetDuo20_21(0) : setBetDuo20_21(betduo20_21 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetDuo23_24(0) : setBetDuo23_24(betduo23_24 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetDuo26_27(0) : setBetDuo26_27(betduo26_27 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetDuo29_30(0) : setBetDuo29_30(betduo29_30 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetDuo32_33(0) : setBetDuo32_33(betduo32_33 + chipSelector.value);
        break;
      case 12:
        removeBet ? setBetDuo35_36(0) : setBetDuo35_36(betduo35_36 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handleQuadBetSelector1_2_34_35 = (value) => {
      switch (value) {
      case 0:
        removeBet ? setBetTrio0_1_2(0) : setBetTrio0_1_2(bettrio0_1_2 + chipSelector.value);
        break;
      case 1:
        removeBet ? setBetQuad1_2_4_5(0) : setBetQuad1_2_4_5(betquad1_2_4_5 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetQuad4_5_7_8(0) : setBetQuad4_5_7_8(betquad4_5_7_8 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetQuad7_8_10_11(0) : setBetQuad7_8_10_11(betquad7_8_10_11 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetQuad10_11_13_14(0) : setBetQuad10_11_13_14(betquad10_11_13_14 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetQuad13_14_16_17(0) : setBetQuad13_14_16_17(betquad13_14_16_17 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetQuad16_17_19_20(0) : setBetQuad16_17_19_20(betquad16_17_19_20 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetQuad19_20_22_23(0) : setBetQuad19_20_22_23(betquad19_20_22_23 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetQuad22_23_25_26(0) : setBetQuad22_23_25_26(betquad22_23_25_26 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetQuad25_26_28_29(0) : setBetQuad25_26_28_29(betquad25_26_28_29 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetQuad28_29_31_32(0) : setBetQuad28_29_31_32(betquad28_29_31_32 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetQuad31_32_34_35(0) : setBetQuad31_32_34_35(betquad31_32_34_35 + chipSelector.value);
        break;
      default:
        break;
      }
    };
    
    const handleQuadBetSelector2_3_35_36 = (value) => {
      switch (value) {
      case 0 :
        removeBet ? setBetTrio00_2_3(0) : setBetTrio00_2_3(bettrio00_2_3 + chipSelector.value);
        break;
      case 1:
        removeBet ? setBetQuad2_3_5_6(0) : setBetQuad2_3_5_6(betquad2_3_5_6 + chipSelector.value);
        break;
      case 2:
        removeBet ? setBetQuad5_6_8_9(0) : setBetQuad5_6_8_9(betquad5_6_8_9 + chipSelector.value);
        break;
      case 3:
        removeBet ? setBetQuad8_9_11_12(0) : setBetQuad8_9_11_12(betquad8_9_11_12 + chipSelector.value);
        break;
      case 4:
        removeBet ? setBetQuad11_12_14_15(0) : setBetQuad11_12_14_15(betquad11_12_14_15 + chipSelector.value);
        break;
      case 5:
        removeBet ? setBetQuad14_15_17_18(0) : setBetQuad14_15_17_18(betquad14_15_17_18 + chipSelector.value);
        break;
      case 6:
        removeBet ? setBetQuad17_18_20_21(0) : setBetQuad17_18_20_21(betquad17_18_20_21 + chipSelector.value);
        break;
      case 7:
        removeBet ? setBetQuad20_21_23_24(0) : setBetQuad20_21_23_24(betquad20_21_23_24 + chipSelector.value);
        break;
      case 8:
        removeBet ? setBetQuad23_24_26_27(0) : setBetQuad23_24_26_27(betquad23_24_26_27 + chipSelector.value);
        break;
      case 9:
        removeBet ? setBetQuad26_27_29_30(0) : setBetQuad26_27_29_30(betquad26_27_29_30 + chipSelector.value);
        break;
      case 10:
        removeBet ? setBetQuad29_30_32_33(0) : setBetQuad29_30_32_33(betquad29_30_32_33 + chipSelector.value);
        break;
      case 11:
        removeBet ? setBetQuad32_33_35_36(0) : setBetQuad32_33_35_36(betquad32_33_35_36 + chipSelector.value);
        break;
      default:
        break;
      }
    };

    const setBets = async(userId) =>{
    
      try {
        const response = await axios.post(`${url}/funRoulette/createGame`, {
          userId,
          totBetAmount,
          bet1to18,
          bet19to36,
          bet1to12,
          bet13to24,
          bet25to36,
          beteven,
          betodd,
          betred,
          betblack,
          betfullRow1,
          betfullRow2,
          betfullRow3,
          betsmallRow1,
          betsmallRow2,
          betsmallRow3,
          betsmallRow4,
          betsmallRow5,
          betsmallRow6,
          betsmallRow7,
          betsmallRow8,
          betsmallRow9,
          betsmallRow10,
          betsmallRow11,
          betsmallRow12,
          betduo1_4,
          betduo4_7,
          betduo7_10,
          betduo10_13,
          betduo13_16,
          betduo16_19,
          betduo19_22,
          betduo22_25,
          betduo25_28,
          betduo28_31,
          betduo31_34,
          betduo2_5,
          betduo5_8,
          betduo8_11,
          betduo11_14,
          betduo14_17,
          betduo17_20,
          betduo20_23,
          betduo23_26,
          betduo26_29,
          betduo29_32,
          betduo32_35,
          betduo3_6,
          betduo6_9,
          betduo9_12,
          betduo12_15,
          betduo15_18,
          betduo18_21,
          betduo21_24,
          betduo24_27,
          betduo27_30,
          betduo30_33,
          betduo33_36,
          betduo1_2,
          betduo2_3,
          betduo4_5,
          betduo5_6,
          betduo7_8,
          betduo8_9,
          betduo10_11,
          betduo11_12,
          betduo13_14,
          betduo14_15,
          betduo16_17,
          betduo17_18,
          betduo19_20,
          betduo20_21,
          betduo22_23,
          betduo23_24,
          betduo25_26,
          betduo26_27,
          betduo28_29,
          betduo29_30,
          betduo31_32,
          betduo32_33,
          betduo34_35,
          betduo35_36,
          betduo0_1,
          betduo00_3,
          bettrio0_1_2,
          bettrio0_00_2,
          bettrio00_2_3,
          betsingle1,
          betsingle4,
          betsingle7,
          betsingle10,
          betsingle13,
          betsingle16,
          betsingle19,
          betsingle22,
          betsingle25,
          betsingle28,
          betsingle31,
          betsingle34,
          betsingle2,
          betsingle5,
          betsingle8,
          betsingle11,
          betsingle14,
          betsingle17,
          betsingle20,
          betsingle23,
          betsingle26,
          betsingle29,
          betsingle32,
          betsingle35,
          betsingle3,
          betsingle6,
          betsingle9,
          betsingle12,
          betsingle15,
          betsingle18,
          betsingle21,
          betsingle24,
          betsingle27,
          betsingle30,
          betsingle33,
          betsingle36,
          betsingle0,
          betsingle00,
          betquad1_2_4_5,
          betquad2_3_5_6,
          betquad4_5_7_8,
          betquad5_6_8_9,
          betquad7_8_10_11,
          betquad8_9_11_12,
          betquad10_11_13_14,
          betquad11_12_14_15,
          betquad13_14_16_17,
          betquad14_15_17_18,
          betquad16_17_19_20,
          betquad17_18_20_21,
          betquad19_20_22_23,
          betquad20_21_23_24,
          betquad22_23_25_26,
          betquad23_24_26_27,
          betquad25_26_28_29,
          betquad26_27_29_30,
          betquad28_29_31_32,
          betquad29_30_32_33,
          betquad31_32_34_35,
          betquad32_33_35_36
        },{
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
      }}
        ,);
        // alert(`Game created successfully: ${response.data.val } , ${response.data.returnAmt}`);
        console.log("#############################",response.data.returnAmt,response.data.val)
        setWinAmount(response.data.returnAmt)
        setWinNumber(response.data.val)
        setTimeout(() => {
          setFRGameOver(true)
        }, 2000);
        
      } catch (error) {
        alert(`Error creating game: ${error.message}`);
      }
  }

  useEffect(() => {
      setTotBetAmount(
        bet1to18 +
        bet19to36 +
        bet1to12 +
        bet13to24 +
        bet25to36 +
        beteven +
        betodd +
        betred +
        betblack +
        betfullRow1 +
        betfullRow2 +
        betfullRow3 +
        betsmallRow1 +
        betsmallRow2 +
        betsmallRow3 +
        betsmallRow4 +
        betsmallRow5 +
        betsmallRow6 +
        betsmallRow7 +
        betsmallRow8 +
        betsmallRow9 +
        betsmallRow10 +
        betsmallRow11 +
        betsmallRow12 +
        betduo1_4 +
        betduo4_7 +
        betduo7_10 +
        betduo10_13 +
        betduo13_16 +
        betduo16_19 +
        betduo19_22 +
        betduo22_25 +
        betduo25_28 +
        betduo28_31 +
        betduo31_34 +
        betduo1_2 +
        betduo2_3 +
        betduo4_5 +
        betduo5_6 +
        betduo7_8 +
        betduo8_9 +
        betduo10_11 +
        betduo11_12 +
        betduo13_14 +
        betduo14_15 +
        betduo16_17 +
        betduo17_18 +
        betduo19_20 +
        betduo20_21 +
        betduo22_23 +
        betduo23_24 +
        betduo25_26 +
        betduo26_27 +
        betduo28_29 +
        betduo29_30 +
        betduo31_32 +
        betduo32_33 +
        betduo34_35 +
        betduo35_36 +
        betquad1_2_4_5 +
        betquad2_3_5_6 +
        betquad4_5_7_8 +
        betquad5_6_8_9 +
        betquad7_8_10_11 +
        betquad8_9_11_12 +
        betquad10_11_13_14 +
        betquad11_12_14_15 +
        betquad13_14_16_17 +
        betquad14_15_17_18 +
        betquad16_17_19_20 +
        betquad17_18_20_21 +
        betquad19_20_22_23 +
        betquad20_21_23_24 +
        betquad22_23_25_26 +
        betquad23_24_26_27 +
        betquad25_26_28_29 +
        betquad26_27_29_30 +
        betquad28_29_31_32 +
        betquad29_30_32_33 +
        betquad31_32_34_35 +
        betquad32_33_35_36 +
        betsingle1 +
        betsingle4 +
        betsingle7 +
        betsingle10 +
        betsingle13 +
        betsingle16 +
        betsingle19 +
        betsingle22 +
        betsingle25 +
        betsingle28 +
        betsingle31 +
        betsingle34 +
        betsingle2 +
        betsingle5 +
        betsingle8 +
        betsingle11 +
        betsingle14 +
        betsingle17 +
        betsingle20 +
        betsingle23 +
        betsingle26 +
        betsingle29 +
        betsingle32 +
        betsingle35 +
        betsingle3 +
        betsingle6 +
        betsingle9 +
        betsingle12 +
        betsingle15 +
        betsingle18 +
        betsingle21 +
        betsingle24 +
        betsingle27 +
        betsingle30 +
        betsingle33 +
        betsingle36 +
        betsingle0 +
        betsingle00 +
        betduo0_1 +
        betduo00_3 +
        bettrio0_1_2 +
        bettrio00_2_3
      );
    }, [
      bet1to18,
      bet19to36,
      bet1to12,
      bet13to24,
      bet25to36,
      beteven,
      betodd,
      betred,
      betblack,
      betfullRow1,
      betfullRow2,
      betfullRow3,
      betsmallRow1,
      betsmallRow2,
      betsmallRow3,
      betsmallRow4,
      betsmallRow5,
      betsmallRow6,
      betsmallRow7,
      betsmallRow8,
      betsmallRow9,
      betsmallRow10,
      betsmallRow11,
      betsmallRow12,
      betduo1_4,
      betduo4_7,
      betduo7_10,
      betduo10_13,
      betduo13_16,
      betduo16_19,
      betduo19_22,
      betduo22_25,
      betduo25_28,
      betduo28_31,
      betduo31_34,
      betduo1_2,
      betduo2_3,
      betduo4_5,
      betduo5_6,
      betduo7_8,
      betduo8_9,
      betduo10_11,
      betduo11_12,
      betduo13_14,
      betduo14_15,
      betduo16_17,
      betduo17_18,
      betduo19_20,
      betduo20_21,
      betduo22_23,
      betduo23_24,
      betduo25_26,
      betduo26_27,
      betduo28_29,
      betduo29_30,
      betduo31_32,
      betduo32_33,
      betduo34_35,
      betduo35_36,
      betquad1_2_4_5,
      betquad2_3_5_6,
      betquad4_5_7_8,
      betquad5_6_8_9,
      betquad7_8_10_11,
      betquad8_9_11_12,
      betquad10_11_13_14,
      betquad11_12_14_15,
      betquad13_14_16_17,
      betquad14_15_17_18,
      betquad16_17_19_20,
      betquad17_18_20_21,
      betquad19_20_22_23,
      betquad20_21_23_24,
      betquad22_23_25_26,
      betquad23_24_26_27,
      betquad25_26_28_29,
      betquad26_27_29_30,
      betquad28_29_31_32,
      betquad29_30_32_33,
      betquad31_32_34_35,
      betquad32_33_35_36,
      betsingle1,
      betsingle4,
      betsingle7,
      betsingle10,
      betsingle13,
      betsingle16,
      betsingle19,
      betsingle22,
      betsingle25,
      betsingle28,
      betsingle31,
      betsingle34,
      betsingle2,
      betsingle5,
      betsingle8,
      betsingle11,
      betsingle14,
      betsingle17,
      betsingle20,
      betsingle23,
      betsingle26,
      betsingle29,
      betsingle32,
      betsingle35,
      betsingle3,
      betsingle6,
      betsingle9,
      betsingle12,
      betsingle15,
      betsingle18,
      betsingle21,
      betsingle24,
      betsingle27,
      betsingle30,
      betsingle33,
      betsingle36,
      betsingle0,
      betsingle00,
      betduo0_1,
      betduo00_3,
      bettrio0_1_2,
      bettrio00_2_3,
    ]);

    const handleBetAmtExecded = () => {
      setBetAmtExecded(!betAmtExecded);
    };  
    
    return (
        <FunRouletteContext.Provider value={{ setBets,betAmtExecded,setBetAmtExecded,handleBetAmtExecded,
            user, chipSelector , totBetAmount ,handlechipSelector, numDetailsArray ,
            bet1to18 , bet19to36 ,betblack,betred,betodd,beteven,bet1to12,bet13to24,bet25to36,
            setBet1to18 , setBet19to36 ,setBetBlack,setBetRed,setBetOdd,setBetEven,setBet1to12,setBet13to24,setBet25to36,
            betfullRow1, betfullRow2, betfullRow3, betsmallRow1, betsmallRow2, betsmallRow3, betsmallRow4, betsmallRow5, betsmallRow6, betsmallRow7, betsmallRow8, betsmallRow9, betsmallRow10, betsmallRow11, betsmallRow12,
            betduo1_4, betduo4_7, betduo7_10, betduo10_13, betduo13_16, betduo16_19, betduo19_22, betduo22_25, betduo25_28, betduo28_31, betduo31_34, betduo2_5, betduo5_8, betduo8_11, betduo11_14, betduo14_17, betduo17_20, betduo20_23, betduo23_26, betduo26_29, betduo29_32, betduo32_35, betduo3_6, betduo6_9, betduo9_12, betduo12_15, betduo15_18, betduo18_21, betduo21_24, betduo24_27, betduo27_30, betduo30_33, betduo33_36,
            betduo1_2, betduo2_3, betduo4_5, betduo5_6, betduo7_8, betduo8_9, betduo10_11, betduo11_12, betduo13_14, betduo14_15, betduo16_17, betduo17_18, betduo19_20, betduo20_21, betduo22_23, betduo23_24, betduo25_26, betduo26_27, betduo28_29, betduo29_30, betduo31_32, betduo32_33, betduo34_35, betduo35_36,
            betduo0_1, betduo00_3, bettrio0_1_2, bettrio00_2_3, bettrio0_00_2,
            betsingle0, betsingle00, betsingle1, betsingle4, betsingle7, betsingle10, betsingle13, betsingle16, betsingle19, betsingle22, betsingle25, betsingle28, betsingle31, betsingle34, betsingle2, betsingle5, betsingle8, betsingle11, betsingle14, betsingle17, betsingle20, betsingle23, betsingle26, betsingle29, betsingle32, betsingle35, betsingle3, betsingle6, betsingle9, betsingle12, betsingle15, betsingle18, betsingle21, betsingle24, betsingle27, betsingle30, betsingle33, betsingle36,
            betquad1_2_4_5, betquad2_3_5_6, betquad4_5_7_8, betquad5_6_8_9, betquad7_8_10_11, betquad8_9_11_12, betquad10_11_13_14, betquad11_12_14_15, betquad13_14_16_17, betquad14_15_17_18, betquad16_17_19_20, betquad17_18_20_21, betquad19_20_22_23, betquad20_21_23_24, betquad22_23_25_26, betquad23_24_26_27, betquad25_26_28_29, betquad26_27_29_30, betquad28_29_31_32, betquad29_30_32_33, betquad31_32_34_35, betquad32_33_35_36,
            setFullBetRow1, setFullBetRow2, setFullBetRow3, setSmallBetRow1, setSmallBetRow2, setSmallBetRow3, setSmallBetRow4, setSmallBetRow5, setSmallBetRow6, setSmallBetRow7, setSmallBetRow8, setSmallBetRow9, setSmallBetRow10, setSmallBetRow11, setSmallBetRow12,
            setBetDuo1_4, setBetDuo4_7, setBetDuo7_10, setBetDuo10_13, setBetDuo13_16, setBetDuo16_19, setBetDuo19_22, setBetDuo22_25, setBetDuo25_28, setBetDuo28_31, setBetDuo31_34, setBetDuo2_5, setBetDuo5_8, setBetDuo8_11, setBetDuo11_14, setBetDuo14_17, setBetDuo17_20, setBetDuo20_23, setBetDuo23_26, setBetDuo26_29, setBetDuo29_32, setBetDuo32_35, setBetDuo3_6, setBetDuo6_9, setBetDuo9_12, setBetDuo12_15, setBetDuo15_18, setBetDuo18_21, setBetDuo21_24, setBetDuo24_27, setBetDuo27_30, setBetDuo30_33, setBetDuo33_36,
            setBetDuo1_2, setBetDuo2_3, setBetDuo4_5, setBetDuo5_6, setBetDuo7_8, setBetDuo8_9, setBetDuo10_11, setBetDuo11_12, setBetDuo13_14, setBetDuo14_15, setBetDuo16_17, setBetDuo17_18, setBetDuo19_20, setBetDuo20_21, setBetDuo22_23, setBetDuo23_24, setBetDuo25_26, setBetDuo26_27, setBetDuo28_29, setBetDuo29_30, setBetDuo31_32, setBetDuo32_33, setBetDuo34_35, setBetDuo35_36,
            setBetDuo0_1, setBetTrio0_1_2, setBetTrio00_2_3, setBetTrio0_00_2,
            setBetSingle0, setBetSingle00, setBetSingle1, setBetSingle4, setBetSingle7, setBetSingle10, setBetSingle13, setBetSingle16, setBetSingle19, setBetSingle22, setBetSingle25, setBetSingle28, setBetSingle31, setBetSingle34, setBetSingle2, setBetSingle5, setBetSingle8, setBetSingle11, setBetSingle14, setBetSingle17, setBetSingle20, setBetSingle23, setBetSingle26, setBetSingle29, setBetSingle32, setBetSingle35, setBetSingle3, setBetSingle6, setBetSingle9, setBetSingle12, setBetSingle15, setBetSingle18, setBetSingle21, setBetSingle24, setBetSingle27, setBetSingle30, setBetSingle33, setBetSingle36,
            setBetQuad1_2_4_5, setBetQuad2_3_5_6, setBetQuad4_5_7_8, setBetQuad5_6_8_9, setBetQuad7_8_10_11, setBetQuad8_9_11_12, setBetQuad10_11_13_14, setBetQuad11_12_14_15, setBetQuad13_14_16_17, setBetQuad14_15_17_18, setBetQuad16_17_19_20, setBetQuad17_18_20_21, setBetQuad19_20_22_23, setBetQuad20_21_23_24, setBetQuad22_23_25_26, setBetQuad23_24_26_27, setBetQuad25_26_28_29, setBetQuad26_27_29_30, setBetQuad28_29_31_32, setBetQuad29_30_32_33, setBetQuad31_32_34_35, setBetQuad32_33_35_36
            ,setBetDuo00_3, removeBet,frgameOver,resetAllBets,winAmount,winNumber,
            handleSmallRowBetSelector,DuoQuadbetBcColorHandler,
            handlesingleBetSelector3_36,handleDuoBetSelector00_36,
            handlesingleBetSelector2_35,handleDuoBetSelector00_35,
            handlesingleBetSelector1_34,handleDuoBetSelector0_34,
            handleDuoSideBetSelector1_2_34_35,handleQuadBetSelector1_2_34_35,
            handleDuoSideBetSelector2_3_35_36,handleQuadBetSelector2_3_35_36
            }}>
            {children}
        </FunRouletteContext.Provider>
    );
};

export default FunRouletteContext;  