import React,{useContext} from 'react'
import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
function RowSplits3() {
    const Array3 = [1,4,7,10,13,16,19,22,25,28,31,34];
    const Array3color = ["red","black","red","black","black","red","red","black","red","black","black","red"];
    const {betduo0_1, betduo1_4, betduo4_7, betduo7_10, betduo10_13, betduo13_16, betduo16_19, betduo19_22, betduo22_25, betduo25_28, betduo28_31, betduo31_34,
      betsingle1, betsingle4, betsingle7, betsingle10, betsingle13, betsingle16, betsingle19, betsingle22, betsingle25, betsingle28, betsingle31, betsingle34,
      DuoQuadbetBcColorHandler,handlesingleBetSelector1_34,handleDuoBetSelector0_34} = useContext(FunRouletteContext);
    const betduoarray0_34 = [betduo0_1, betduo1_4, betduo4_7, betduo7_10, betduo10_13, betduo13_16, betduo16_19, betduo19_22, betduo22_25, betduo25_28, betduo28_31, betduo31_34];
    const betsinglearray1_34 = [betsingle1, betsingle4, betsingle7, betsingle10, betsingle13, betsingle16, betsingle19, betsingle22, betsingle25, betsingle28, betsingle31, betsingle34];
    

  return (
    <>
    {
            Array3.map((val, index) => (
             <>
             <div 
             onClick={()=>{handleDuoBetSelector0_34(index)}}
             className={DuoQuadbetBcColorHandler(val)}
              ><div className="betDuoLinevertical"></div>
               { betduoarray0_34[index] ? <Chip size={{w:"25px",h:"25px"}} value={betduoarray0_34[index]} /> : null}
              </div>
             <div 
             key={index} 
             className="singleBetter flex-set" 
             onClick={()=>{handlesingleBetSelector1_34(index+1)}}
             style={{ backgroundColor: Array3color[index] }}
             ><span style={{transform:`rotate(-90deg)`}}>{val}</span>
             { betsinglearray1_34[index] ? <Chip size={{w:"25px",h:"25px"}} value={betsinglearray1_34[index]} /> : null}
             </div>
             </> 
            ))
            }

    </>
  )
}

export default RowSplits3