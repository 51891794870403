import React,{useContext} from 'react'
import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
function RowSplits1() {
    const Array1 = [3,6,9,12,15,18,21,24,27,30,33,36];
    const Array1color = ["red","black", "red","red","black","red","red","black","red","red","black","red"];
    const {betsingle3, betsingle6, betsingle9, betsingle12, betsingle15, betsingle18, betsingle21, betsingle24, betsingle27, betsingle30, betsingle33, betsingle36,
      betduo00_3,betduo3_6, betduo6_9, betduo9_12, betduo12_15, betduo15_18, betduo18_21, betduo21_24, betduo24_27, betduo27_30, betduo30_33, betduo33_36,
      DuoQuadbetBcColorHandler,handlesingleBetSelector3_36,handleDuoBetSelector00_36} = useContext(FunRouletteContext);
      const betsinglearray3_36 = [betsingle3, betsingle6, betsingle9, betsingle12, betsingle15, betsingle18, betsingle21, betsingle24, betsingle27, betsingle30, betsingle33, betsingle36];
      const betduoarray00_36 = [betduo00_3, betduo3_6, betduo6_9, betduo9_12, betduo12_15, betduo15_18, betduo18_21, betduo21_24, betduo24_27, betduo27_30, betduo30_33, betduo33_36];
  
  return (
    <>
    {
            Array1.map((val, index) => (
             <>
             <div  className={DuoQuadbetBcColorHandler(val)}
                onClick={()=>{handleDuoBetSelector00_36(index)}}>
                <div className="betDuoLinevertical"></div>
                { betduoarray00_36[index] ? <Chip size={{w:"25px",h:"25px"}} value={betduoarray00_36[index]} /> : null}
              </div>
             <div 
             key={index} 
             className="singleBetter flex-set" 
             style={{ backgroundColor: Array1color[index] }}
             onClick={()=>{handlesingleBetSelector3_36(index+1)}}
             ><span style={{transform:`rotate(-90deg)`}}>{val}</span>
             { betsinglearray3_36[index] ? <Chip size={{w:"25px",h:"25px"}} value={betsinglearray3_36[index]} /> : null}
             </div>
             </> 
            ))
            }

    </>
  )
}

export default RowSplits1