import React,{useContext} from 'react'
import './ArcadeButton.css';
import SpinWheelContext from './SpinWheelContext';
import UserContext from '../Login/UserContext';
function ArcadeButton({text}) {
  const {getValue}  = useContext(SpinWheelContext)
  const {user} = useContext(UserContext)
  return (
        <button className="tempbutton push--flat" onClick={()=>{getValue(user.userId)}}>{text}</button>
  )
}

export default ArcadeButton