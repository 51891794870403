import React,{useContext} from 'react'
import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
function RowMidSplits1() {
    const Array2 = [2,5,8,11,14,17,20,23,26,29,32,35];
    const Array1color = ["red","black", "red","red","black","red","red","black","red","red","black","red"];
    const Array2color = ["black","red","black","black","red","black","black","red","black","black","red","black"];
    const {betduo2_3, betduo5_6, betduo8_9, betduo11_12, betduo14_15, betduo17_18, betduo20_21, betduo23_24, betduo26_27, betduo29_30, betduo32_33, betduo35_36,
      bettrio00_2_3,betquad2_3_5_6,betquad5_6_8_9,betquad8_9_11_12,betquad11_12_14_15,betquad14_15_17_18,betquad17_18_20_21,betquad20_21_23_24,betquad23_24_26_27,betquad26_27_29_30,betquad29_30_32_33,betquad32_33_35_36,
      handleDuoSideBetSelector2_3_35_36,handleQuadBetSelector2_3_35_36} = useContext(FunRouletteContext);

      const betsideduoarray2_3_35_36 = [betduo2_3, betduo5_6, betduo8_9, betduo11_12, betduo14_15, betduo17_18, betduo20_21, betduo23_24, betduo26_27, betduo29_30, betduo32_33, betduo35_36];
      const betquadarr2_3_5_6__32_33_35_36 = [bettrio00_2_3,betquad2_3_5_6,betquad5_6_8_9,betquad8_9_11_12,betquad11_12_14_15,betquad14_15_17_18,betquad17_18_20_21,betquad20_21_23_24,betquad23_24_26_27,betquad26_27_29_30,betquad29_30_32_33,betquad32_33_35_36];
    
  return (
    <>
    {         
               Array2.map((val, index) => (
              <>
              <div key={"midsplit1"+index  }
              onClick={()=>{ handleQuadBetSelector2_3_35_36(index)}}
               className="duobettervertical flex-set" >
                <div className="duoquadbetterside flex-set" style={{ background: `linear-gradient(to bottom, ${ val - 3 + 1 > 0 ?  Array1color[index-1] : 'rgba(0,0,0,0)'} 0% , ${ val - 3 + 1 > 0 ?  Array1color[index-1] : 'rgba(0,0,0,0)'} 50%, ${ val - 3  > 0 ?  Array2color[index-1] : 'rgba(0,0,0,0)'} 50% ,${val - 3  > 0 ?  Array2color[index-1] : 'rgba(0,0,0,0)'} 100%)`}}>
                  {val-3 > 0 ? <div className="duoquadhorizantal"></div> : null}
                </div>
                <div className="betDuoLinevertical"></div>
                <div className="duoquadbetterside flex-set" style={{ background: `linear-gradient(to bottom, ${Array1color[index]} 0% , ${Array1color[index]} 50%, ${Array2color[index]} 50% ,${Array2color[index]} 100%)`}} >
                {val > 0 ? <div className="duoquadhorizantal"></div> : null}
                </div>
                { betquadarr2_3_5_6__32_33_35_36[index] ? <Chip size={{w:"25px",h:"25px"}} value={betquadarr2_3_5_6__32_33_35_36[index]} /> : null}
                
              </div>
              <div 
              key={"midsplit1single"+index  } 
              onClick={()=>{ handleDuoSideBetSelector2_3_35_36(index+1)}}
              className="singleBetter flex-set" 
              style={{ background: `linear-gradient(to bottom, ${Array1color[index]} 0% , ${Array1color[index]} 50%, ${Array2color[index]} 50% ,${Array2color[index]} 100%)`}}// numDetailsArray.find(item => item.val === val ).color }}
              >
                <div className="duoquadhorizantal"></div>
                { betsideduoarray2_3_35_36[index] ? <Chip size={{w:"25px",h:"25px"}} value={betsideduoarray2_3_35_36[index]} /> : null}
                  
              </div>
              </> 
             ))
            }
    </>
  )
}

export default RowMidSplits1