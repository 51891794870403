import React,{useContext} from 'react'
import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
function RowSplits2() {
    const Array2 = [2,5,8,11,14,17,20,23,26,29,32,35];
   const Array2color = ["black","red","black","black","red","black","black","red","black","black","red","black"];
 const { bettrio0_00_2,  betduo2_5, betduo5_8, betduo8_11, betduo11_14, betduo14_17, betduo17_20, betduo20_23, betduo23_26, betduo26_29, betduo29_32, betduo32_35,
  betsingle2, betsingle5, betsingle8, betsingle11, betsingle14, betsingle17, betsingle20, betsingle23, betsingle26, betsingle29, betsingle32, betsingle35,
  DuoQuadbetBcColorHandler,handlesingleBetSelector2_35,handleDuoBetSelector00_35
} = useContext(FunRouletteContext);
const betduoarray0_35 = [bettrio0_00_2, betduo2_5, betduo5_8, betduo8_11, betduo11_14, betduo14_17, betduo17_20, betduo20_23, betduo23_26, betduo26_29, betduo29_32, betduo32_35];
const betsinglearray2_35 = [betsingle2, betsingle5, betsingle8, betsingle11, betsingle14, betsingle17, betsingle20, betsingle23, betsingle26, betsingle29, betsingle32, betsingle35];

  return (
    <>
    {
            Array2.map((val, index) => (
             <>
             <div  className={DuoQuadbetBcColorHandler(val)}
             onClick={()=>{handleDuoBetSelector00_35(index)}}
              ><div className="betDuoLinevertical"></div>
                { betduoarray0_35[index] ? <Chip size={{w:"25px",h:"25px"}} value={betduoarray0_35[index]} /> : null}
              </div>
             <div 
             key={index} 
             onClick={()=>{handlesingleBetSelector2_35(index+1)}}
             className="singleBetter flex-set" 
             style={{ backgroundColor: Array2color[index] }}
             ><span style={{transform:`rotate(-90deg)`}}>{val}</span>
              { betsinglearray2_35[index] ? <Chip size={{w:"25px",h:"25px"}} value={betsinglearray2_35[index]} /> : null}
             </div>
             </> 
            ))
            }

    </>
  )
}

export default RowSplits2