import './styleG/App.css';
// import Login from './pages/Login/Login';
import { UserProvider } from './pages/Login/UserContext';
import { useEffect } from 'react';
import Main from './pages/Main/Main';
function App() {
  console.log(window.screen.orientation.type)
  const orientation = window.screen.orientation.type
  useEffect( ()=>{
    if(orientation ==="portrait-primary"){
     alert("Please Rotate the device to change orientation")
    }
  },[orientation])
  
  
  return (
    <UserProvider>
    <div className="App">
      <Main/>
    </div>
    </UserProvider>
  );
}

export default App;
