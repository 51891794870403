import React, { useEffect } from 'react'
import './GamePad.css'
import Square from './Square'
import  DiamondMinerContext  from "./DiamondMinerContext.jsx";
import { useContext } from 'react';
import Preset from '../../components/Helper/Preset.jsx'
import WLpopup from '../../components/Helper/WLpopup.jsx';




function GamePad() {
    const numrows = 5;
    const numcols = 5;
    // const { user } = useContext(UserContext);
    const { resetAlert,  alert ,loss,  squares,gameOver, win ,winVal,resetAllStates } = useContext(DiamondMinerContext);
    // const squares = squares;
  const rows = [];
  // 
  const generateRows = () =>{
    for (let i = 0; i < numrows; i++) {
      if(gameOver){
        rows.push(<div className='squareRows' key={i}>
          {squares.slice(i * numcols, (i + 1) * numcols).map((square, j) => (
           
            square === "bomb" ? 
            <Square  rowid={i} colid={j} key={j} value={"💣"}  /> 
            :
            <Square  rowid={i} colid={j} key={j} value={"💎"}  /> 
           
          ))}
      </div>)
      }
      else{
        rows.push(<div className='squareRows' key={i}>
          {squares.slice(i * numcols, (i + 1) * numcols).map((square, j) => (
           <Square  rowid={i} colid={j} key={j} value={"null"}  /> 
          ))}
      </div>)
      }
    }
  }
  generateRows()
  useEffect(()=>{
    generateRows()
  },[gameOver])
  
  
  
 return (
    <div className='gamePad'>
      <div className='gamePadInner'>{rows}</div>
     {win ? <> <WLpopup popopen={true} win={true} func={resetAllStates} text1={"Diamond Miner Winner"} text2={`You have won ${winVal}`} text3={`${winVal}`} time={1000} /><Preset preset="fireworks"/> </>
     : null } 
    {loss ? <> <WLpopup  popopen={true} func={resetAllStates} text1={"Diamond Miner Looser"} text2={`You have Lost.`} time={1000} /><Preset preset="snow" color="red"/> </>  : null}
      {alert ? <WLpopup  popopen={true} func={resetAlert} text1={"Diamond Miner Alert"} text2={`Please BET first.`} time={200} /> : null}
      </div>
  )
}

export default GamePad