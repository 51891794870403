import React,{useContext} from 'react'
import './RouletteBetTable.css'
import RussianRouletteContext from './RussianRouletteMainContext'
import Chip from '../../components/Helper/Chip';


function RouletteBetTable() {
  const {user,spin,chipSelector,totbetAmount,
    bet1to18,
    setBet1to18,
    bet19to36,
    setBet19to36,
    beteven,
    setBetEven,
    betodd,
    setBetOdd,
    betred,
    setBetRed,
    betblack,
    setBetBlack, 
    bet1to12,
    setBet1to12,
    bet13to24,
    setBet13to24,
    bet25to36,
    setBet25to36,
    betfullRow1,
    setFullBetRow1,
    betfullRow2,
    setFullBetRow2,
    betfullRow3,
    setFullBetRow3,
    handleSmallRowBetSelector,
    betsmallRow1,
    betsmallRow2,
    betsmallRow3,
    betsmallRow4,
    betsmallRow5,
    betsmallRow6,
    betsmallRow7,
    betsmallRow8,
    betsmallRow9,
    betsmallRow10,
    betsmallRow11,
    betsmallRow12,
    handleDuoBetSelector0_34,
    betduo0_1,
    betduo1_4,
    betduo4_7,
    betduo7_10,
    betduo10_13,
    betduo13_16,
    betduo16_19,
    betduo19_22,
    betduo22_25,
    betduo25_28,
    betduo28_31,
    betduo31_34,
    handlesingleBetSelector1_34,
    betsingle1,
    betsingle4,
    betsingle7,
    betsingle10,
    betsingle13,
    betsingle16,
    betsingle19,
    betsingle22,
    betsingle25,
    betsingle28,
    betsingle31,
    betsingle34,
    handleDuoBetSelector0_35,
    betduo0_2,
    betduo2_5,
    betduo5_8,
    betduo8_11,
    betduo11_14,
    betduo14_17,
    betduo17_20,
    betduo20_23,
    betduo23_26,
    betduo26_29,
    betduo29_32,
    betduo32_35,
    handlesingleBetSelector2_35,
    betsingle2,
    betsingle5,
    betsingle8,
    betsingle11,
    betsingle14,
    betsingle17,
    betsingle20,
    betsingle23,
    betsingle26,
    betsingle29,
    betsingle32,
    betsingle35,
    handleDuoBetSelector0_36,
    betduo0_3,
    betduo3_6,
    betduo6_9,
    betduo9_12,
    betduo12_15,
    betduo15_18,
    betduo18_21,
    betduo21_24,
    betduo24_27,
    betduo27_30,
    betduo30_33,
    betduo33_36,
    handlesingleBetSelector3_36,
    betsingle3,
    betsingle6,
    betsingle9,
    betsingle12,
    betsingle15,
    betsingle18,
    betsingle21,
    betsingle24,
    betsingle27,
    betsingle30,
    betsingle33,
    betsingle36,
    betsingle0,
    setBetSingle0,
    handleDuoSideBetSelector1_2_34_35,
    betduo1_2,
    betduo4_5,
    betduo7_8,
    betduo10_11,
    betduo13_14,
    betduo16_17,
    betduo19_20,
    betduo22_23,
    betduo25_26,
    betduo28_29,
    betduo31_32,
    betduo34_35,
    handleDuoSideBetSelector2_3_35_36,
    betduo2_3,
    betduo5_6,
    betduo8_9,
    betduo11_12,
    betduo14_15,
    betduo17_18,
    betduo20_21,
    betduo23_24,
    betduo26_27,
    betduo29_30,
    betduo32_33,
    betduo35_36,
    handleQuadBetSelector1_2_34_35,
    betquad1_2_4_5,
    betquad2_3_5_6,
    betquad4_5_7_8,
    betquad5_6_8_9,
    betquad7_8_10_11,
    betquad8_9_11_12,
    betquad10_11_13_14,
    betquad11_12_14_15,
    betquad13_14_16_17,
    betquad14_15_17_18,
    betquad16_17_19_20,
    betquad17_18_20_21,
    betquad19_20_22_23,
    betquad20_21_23_24,
    betquad22_23_25_26,
    betquad23_24_26_27,
    betquad25_26_28_29,
    betquad26_27_29_30,
    betquad28_29_31_32,
    betquad29_30_32_33,
    betquad31_32_34_35,
    betquad32_33_35_36,
    handleQuadBetSelector2_3_35_36,
    bettrio0_1_2,
    bettrio0_2_3,
    numDetailsArray,setBetAmtExecded,
    } = useContext(RussianRouletteContext);
  const Array1 = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36]
  const Array2 = [2, 5, 8, 11, 14, 17, 20, 23, 26, 29, 32, 35]
  const Array3 = [1, 4, 7, 10, 13, 16, 19, 22, 25, 28, 31, 34]

    
  const betsmallrowarray = [betsmallRow1, betsmallRow2, betsmallRow3, betsmallRow4, betsmallRow5, betsmallRow6, betsmallRow7, betsmallRow8, betsmallRow9, betsmallRow10, betsmallRow11, betsmallRow12];
  const betduoarray0_34 = [betduo0_1, betduo1_4, betduo4_7, betduo7_10, betduo10_13, betduo13_16, betduo16_19, betduo19_22, betduo22_25, betduo25_28, betduo28_31, betduo31_34];
  const betsinglearray1_34 = [betsingle1, betsingle4, betsingle7, betsingle10, betsingle13, betsingle16, betsingle19, betsingle22, betsingle25, betsingle28, betsingle31, betsingle34];
  const betduoarray0_35 = [betduo0_2, betduo2_5, betduo5_8, betduo8_11, betduo11_14, betduo14_17, betduo17_20, betduo20_23, betduo23_26, betduo26_29, betduo29_32, betduo32_35];
  const betsinglearray2_35 = [betsingle2, betsingle5, betsingle8, betsingle11, betsingle14, betsingle17, betsingle20, betsingle23, betsingle26, betsingle29, betsingle32, betsingle35];
  const betduoarray0_36 = [betduo0_3, betduo3_6, betduo6_9, betduo9_12, betduo12_15, betduo15_18, betduo18_21, betduo21_24, betduo24_27, betduo27_30, betduo30_33, betduo33_36];
  const betsinglearray3_36 = [betsingle3, betsingle6, betsingle9, betsingle12, betsingle15, betsingle18, betsingle21, betsingle24, betsingle27, betsingle30, betsingle33, betsingle36];
  const betsideduoarray1_2_34_35 = [betduo1_2, betduo4_5, betduo7_8, betduo10_11, betduo13_14, betduo16_17, betduo19_20, betduo22_23, betduo25_26, betduo28_29, betduo31_32, betduo34_35];
  const betsideduoarray2_3_35_36 = [betduo2_3, betduo5_6, betduo8_9, betduo11_12, betduo14_15, betduo17_18, betduo20_21, betduo23_24, betduo26_27, betduo29_30, betduo32_33, betduo35_36];
  const betquadarr1_2_4_7__31_32_34_35  = [bettrio0_1_2,betquad1_2_4_5,betquad4_5_7_8,betquad7_8_10_11,betquad10_11_13_14,betquad13_14_16_17,betquad16_17_19_20,betquad19_20_22_23,betquad22_23_25_26,betquad25_26_28_29,betquad28_29_31_32,betquad31_32_34_35];
  const betquadarr2_3_5_6__32_33_35_36 = [bettrio0_2_3,betquad2_3_5_6,betquad5_6_8_9,betquad8_9_11_12,betquad11_12_14_15,betquad14_15_17_18,betquad17_18_20_21,betquad20_21_23_24,betquad23_24_26_27,betquad26_27_29_30,betquad29_30_32_33,betquad32_33_35_36];

  return (
    <div className={ spin ? 'bettingTabledesign disableclick': 'bettingTabledesign'}>
      <div className="singleBetLabel">
        <div className='singlebetvisible' >
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBet1to18(  bet1to18 + chipSelector.value) }} className="upto18 cursor flex-set"><span >1 to 18</span>{ bet1to18 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={bet1to18}  /> : null }</div>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBet19to36( bet19to36 + chipSelector.value) }} className="upto36 cursor flex-set"><span >19 to 36</span>{ bet19to36 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={bet19to36}  /> : null }</div>
        </div>
        <div className="remaining"></div>
      </div>
      <div className="mainTable">
        <div 
        onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetSingle0( betsingle0 + chipSelector.value) }}
         className='zeroAlign cursor flex-set'>
          <span style={{transform:`rotate(-90deg)`}}>0</span>
          { betsingle0 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betsingle0} /> : null }
          </div>
        <div className="betTable flex-set">
          {/* 1st row */}
          <div className="row1">
            {Array1.map((val, index) => (
              <>
                <div 
                onClick={()=>{handleDuoBetSelector0_36(index)}}
                className="cellT2  flex-set">
                  { betduoarray0_36[index] ? <Chip size={{w:"18px",h:"18px"}} value={betduoarray0_36[index]} /> : null}
                  </div>
                <div 
                key={index} 
                onClick={()=>{handlesingleBetSelector3_36(index+1)}}
                className={`cellT1  topwhiteborde `} 
                style={{ backgroundColor: numDetailsArray.find(item => item.val === val).color }}
                > {/*${ _ in red ? "colorRed" : ""} */}
                  <span style={{transform:`rotate(-90deg)`}}>{ val }</span>
                  { betsinglearray3_36[index] ? <Chip size={{w:"18px",h:"18px"}} value={betsinglearray3_36[index]} /> : null}
                </div>
              </>
            ))}
            <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setFullBetRow1( betfullRow1 + chipSelector.value) }} className="rowbetfull cursor"><span style={{transform:`rotate(-90deg)`}}>2 to 1</span>{ betfullRow1 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betfullRow1} /> : null }</div>
          </div>
          {/* horizantal betting 1st row */}
          <div className="rowbet1 flex-set" id='rowbet1'>
            {Array1.map((_, index) => (
              <>
                <div 
                onClick={()=>{ handleQuadBetSelector2_3_35_36(index)}}
                className="verticalcellT1 cursor flex-set" 
                style={ index === 0 ? {width:'10px'} :  {}}> 

                { betquadarr2_3_5_6__32_33_35_36[index] ? <Chip size={{w:"18px",h:"18px"}} value={betquadarr2_3_5_6__32_33_35_36[index]} /> : null}
                </div>
                <div 
                onClick={()=>{ handleDuoSideBetSelector2_3_35_36(index+1)}}
                className="verticalcellT2 cursor flex-set">
                  { betsideduoarray2_3_35_36[index] ? <Chip size={{w:"18px",h:"18px"}} value={betsideduoarray2_3_35_36[index]} /> : null}
                  </div>
              </>
            ))}
          </div>
          {/* 2nd row */}
          <div className="row2">
            {Array2.map((val, index) => (
              <>
                <div 
                onClick={()=>{handleDuoBetSelector0_35(index)}}
                className="cellT2 cursor flex-set">
                  { betduoarray0_35[index] ? <Chip size={{w:"18px",h:"18px"}} value={betduoarray0_35[index]} /> : null}
                </div>
                <div 
                onClick={()=>{handlesingleBetSelector2_35(index+1)}}
                key={index} 
                className={`cellT1 `}
                style={{ backgroundColor: numDetailsArray.find(item => item.val === val).color }}
                >
                  <span style={{transform:`rotate(-90deg)`}}>{val}</span>
                  { betsinglearray2_35[index] ? <Chip size={{w:"18px",h:"18px"}} value={betsinglearray2_35[index]} /> : null}
                </div>
              </>
            ))}
            
            <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setFullBetRow2( betfullRow2 + chipSelector.value) }} className="rowbetfull cursor"><span style={{transform:`rotate(-90deg)`}}>2 to 1</span>{ betfullRow2 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betfullRow2} /> : null }</div>
          </div>
          <div className="rowbet2 cursor flex-set" id='rowbet2'>
            {Array1.map((_, index) => (
              <>
                <div 
                onClick={()=>{ handleQuadBetSelector1_2_34_35(index)}}
                className="verticalcellT1 cursor flex-set" style={ index === 0 ? {width:'10px'} :  {}}>
                  { betquadarr1_2_4_7__31_32_34_35[index] ? <Chip size={{w:"18px",h:"18px"}} value={betquadarr1_2_4_7__31_32_34_35[index]} /> : null}
                  </div>
                <div 
                onClick={()=>{ handleDuoSideBetSelector1_2_34_35(index+1)}}
                className="verticalcellT2 cursor flex-set">
                  { betsideduoarray1_2_34_35[index] ? <Chip size={{w:"18px",h:"18px"}} value={betsideduoarray1_2_34_35[index]} /> : null}
                  </div>
              </>
            ))}
          </div>
          {/* 3rd row */}
          <div className="row3">
            {Array3.map((val, index) => (
              <>
                <div 
                 onClick={()=>{handleDuoBetSelector0_34(index)}}
                className="cellT2 cursor flex-set">
                  { betduoarray0_34[index] ? <Chip size={{w:"18px",h:"18px"}} value={betduoarray0_34[index]} /> : null}
                  </div>
                <div 
                onClick={()=>{handlesingleBetSelector1_34(index+1)}}
                className={`cellT1 `}
                style={{ backgroundColor: numDetailsArray.find(item => item.val === val).color }}
                >
                  <span style={{transform:`rotate(-90deg)`}}>{val}</span>
                  { betsinglearray1_34[index] ? <Chip size={{w:"18px",h:"18px"}} value={betsinglearray1_34[index]} /> : null}
                </div>
              </>
            ))}
            <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setFullBetRow3( betfullRow3 + chipSelector.value) }} className="rowbetfull cursor"><span style={{transform:`rotate(-90deg)`}}>2 to 1</span>{ betfullRow3 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betfullRow3} /> : null }</div>
          </div>
          <div className="rowbet3 flex-set" id='rowbet3'>
            {Array1.map((_, index) => (
              <>
               <div  
               key={'rowbet3_0_'+index} 
               className="verticalcellT1 cursor flex-set"  
               style={ index === 0 ? {width:'10px'} : {} } >  
               </div> 
               
                <div 
                key={'rowbet3_1_'+index} 
                onClick={()=>{handleSmallRowBetSelector(index+1)}} 
                className="verticalcellT2 cursor flex-set">
                 { betsmallrowarray[index] ? <Chip size={{w:"18px",h:"18px"}} value={betsmallrowarray[index]} /> : null}  
                  </div>
                </>
            ))}
          </div>
        </div>
      </div>   
      <div className="singleBetLabel">
        <div className='singlebetvisible'>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBet1to12( bet1to12 + chipSelector.value) }} className="first12  flex-set"><span >1 to 12</span>{ bet1to12 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={bet1to12}  /> : null }</div>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBet13to24( bet13to24 + chipSelector.value) }} className="second12  flex-set"><span >13 to 24</span>{ bet13to24 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={bet13to24}  /> : null }</div>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBet25to36( bet25to36 + chipSelector.value) }} className="third12  flex-set"><span >25 to 36</span>{ bet25to36 > 0 ? <Chip size={{w:"20px",h:"20px"}} value={bet25to36}  /> : null }</div>
        </div>
        <div className="remaining"></div>
      </div>
      <div className="singleBetLabel">
        <div className='singlebetvisible'>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetEven( beteven + chipSelector.value) }} className="even cursor flex-set"><span >Even</span>{ beteven > 0 ? <Chip size={{w:"20px",h:"20px"}} value={beteven}  /> : null }</div>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetRed( betred + chipSelector.value) }} className="red cursor flex-set"><span >Red</span>{ betred > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betred}  /> : null }</div>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetBlack( betblack + chipSelector.value) }} className="black cursor flex-set"><span >Black</span>{ betblack > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betblack}  /> : null }</div>
          <div onClick={()=>{ totbetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : setBetOdd( betodd + chipSelector.value) }} className="odd cursor flex-set"><span >Odd</span>{ betodd > 0 ? <Chip size={{w:"20px",h:"20px"}} value={betodd}  /> : null }</div>
        </div>
        <div className="remaining"></div>
      </div>                                                                          
    </div>
  )
}

export default RouletteBetTable