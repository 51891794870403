import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../../components/Helper/Button'
import './Fun_Roulette_Main.css';
import BettingTable from './BettingTable'
import FunctionPad from './FunctionPad'
import img2 from "./img/fr.png";
import { FunRouletteProvider } from './Fun_Roulette_Context';
function FunRouletteMain() {
  return (
    <FunRouletteProvider>
      <div className='funRouletteMain'  style={{ backgroundImage: `url(${img2})` }}>
        <BettingTable />
        <FunctionPad />
        <Link to="/">
          <Button addClass={"buttonposition"} text="←" />
        </Link>
      </div>
    </FunRouletteProvider>
  )
}

export default FunRouletteMain