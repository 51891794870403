import React,{useContext} from 'react'
import FunRouletteContext from './Fun_Roulette_Context'
import Chip from "../../components/Helper/Chip"
import './Chipset.css'

function Chipset() {
    const {chipSelector,handlechipSelector } = useContext(FunRouletteContext)

  return (
    <div className="chipsetFR">
        <div onClick={(e) => {handlechipSelector(1)} } className={ chipSelector.value === 1 ? 'selectedchip flex-set':'chipselectordiv flex-set'} ><Chip size={{w:"50px",h:"50px"}} value={1} /></div>
        <div onClick={(e) => {handlechipSelector(5)} } className={chipSelector.value === 5 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"50px",h:"50px"}} value={5} /></div>
        <div onClick={(e) => {handlechipSelector(10)} } className={chipSelector.value === 10 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"50px",h:"50px"}} value={10} /></div>
        <div onClick={(e) => {handlechipSelector(50)} } className={chipSelector.value === 50 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"50px",h:"50px"}} value={50} /></div>
        <div onClick={(e) => {handlechipSelector(100)} } className={chipSelector.value === 100 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"50px",h:"50px"}} value={100} /></div>
        <div onClick={(e) => {handlechipSelector(500)} } className={chipSelector.value === 500 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"50px",h:"50px"}} value={500} /></div>
      </div>
  )
}

export default Chipset