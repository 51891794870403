import React from 'react'
import './FunctionPad.css'
import ChipSet from './Chipset.jsx';
import TokenDisplay from './TokenDisplay.jsx';
import BetButtons from './BetButtons.jsx';

function FunctionPad() {
  return (
    <div className='functionpadDesign' >
      <ChipSet />
      <TokenDisplay />
      <BetButtons /> 
    </div>
  )
}

export default FunctionPad