import React,{useContext} from "react";
import "./BettingTable.css";

import FunRouletteContext from "./Fun_Roulette_Context";
import Chip from "../../components/Helper/Chip";
import WLpopup from '../../components/Helper/WLpopup';
import RowSplits1 from "./RowSplits1";
import RowSplits2 from "./RowSplits2";
import RowSplits3 from "./RowSplits3";
import RowMidSplits1 from "./RowMidSplits1";
import RowMidSplits2 from "./RowMidSplits2";
import RowSmallSplits from "./RowSmallSplits";
import Preset from '../../components/Helper/Preset'

function BettingTable() {
  const {winNumber, setBetSingle00,setBetSingle0, betsingle0, betsingle00, chipSelector , user , totBetAmount,setBetAmtExecded,betAmtExecded,gameOver, handleBetAmtExecded
    ,bet1to18 , bet19to36 ,betblack,betred,betodd,beteven,bet1to12,bet13to24,bet25to36,setBet1to18 , setBet19to36 ,setBetBlack,setBetRed,setBetOdd,setBetEven,setBet1to12,setBet13to24,setBet25to36,removeBet,
    betfullRow1, betfullRow2, betfullRow3, setFullBetRow1, setFullBetRow2, setFullBetRow3,
    resetAllBets,winAmount, frgameOver } = useContext(FunRouletteContext);
 
  return (
    <div
      className="FunRbettingtabledesign"
    >
      <div className="TopSide0123FR">
        <div className="Zero00FR">
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBetSingle00(0)  : setBetSingle00(betsingle00+ chipSelector.value)}} className="splitzeros flex-set zero1"><span style={{ transform:`rotate(-90deg)`}}>00</span>{ betsingle00 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betsingle00} /> : null }</div>
          <div className="zerobetter flex-set"><div className="betzeroline"></div></div>
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBetSingle0(0)  : setBetSingle0(betsingle0+ chipSelector.value)}} className="splitzeros flex-set zero2"><span style={{transform:`rotate(-90deg)`}}>0</span>{ betsingle0 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betsingle0} /> : null }</div>
        </div>
        <div className="Row3FR">
          {/* Small Row of Three Betting Line */}
          <div className="smallrowbetter flex-set">
            <RowSmallSplits/>
          </div>
          {/* Main Row 3 Betting Line */}
          <div className="RowFR R1 flex-set">
            <RowSplits1/>
            </div>
            {/* Duo and quad bettting on line1  */}
          <div className="duoquadrowbetter flex-set">
            <RowMidSplits1/>
          </div>
          {/*  Main Row 2 Betting Line */}
          <div className="RowFR R2 flex-set">
          <RowSplits2/>
          </div>
          {/*  Duo and quad bettting on line1   */}
          <div className="duoquadrowbetter flex-set">
            <RowMidSplits2/>
          </div>
          {/*  Main Row 1 Betting Line */}
          <div className="RowFR R3 flex-set">
          <RowSplits3/>
          </div>
        </div>
        <div className="TwoForOne">
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setFullBetRow3(0)  : setFullBetRow3(betfullRow3+ chipSelector.value)}} className="two21FR flex-set"><span>2 TO 1 </span>{ betfullRow3 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betfullRow3} /> : null }</div>
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setFullBetRow2(0)  : setFullBetRow2(betfullRow2+ chipSelector.value)}} className="two21FR flex-set bordermiddle2to1"><span>2 TO 1</span>{ betfullRow2 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betfullRow2} /> : null }</div>
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setFullBetRow1(0)  : setFullBetRow1(betfullRow1+ chipSelector.value)}} className="two21FR flex-set"><span>2 TO 1</span>{ betfullRow1 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betfullRow1} /> : null }</div>
        </div>
      </div>
      <div className="bottom3FR">
      <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBet1to12(0)  : setBet1to12(bet1to12+ chipSelector.value)}} className="better12 flex-set"><span>1 TO 12 </span>{ bet1to12 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={bet1to12} /> : null }</div>
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBet13to24(0)  : setBet13to24(bet13to24+ chipSelector.value)}} className="better12 flex-set borderlr "><span>13 TO 24</span>{ bet13to24 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={bet13to24} /> : null }</div>
          <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBet25to36(0)  : setBet25to36(bet25to36+ chipSelector.value)}} className="better12 flex-set"><span>25 TO 36</span>{ bet25to36 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={bet25to36} /> : null }</div>
        
      </div>
      <div className="Bottom6FR">
        <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBet1to18(0)  : setBet1to18( bet1to18 + chipSelector.value) }}  className="splitequal CornerS18FR"><span> 1 TO 18 </span>{ bet1to18 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={bet1to18} /> : null }</div>
        <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBetEven(0)  : setBetEven( beteven + chipSelector.value) }} className="splitequal betevenFR"><span>EVEN</span>{ beteven > 0 ? <Chip size={{w:"25px",h:"25px"}} value={beteven} /> : null }</div>
        <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBetRed(0)  : setBetRed( betred + chipSelector.value) }} className="splitequal"><div className="rhom red"></div>{ betred > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betred} /> : null }</div>
        <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBetBlack(0)  : setBetBlack( betblack + chipSelector.value) }} className="splitequal"><div className="rhom black"></div>{ betblack > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betblack} /> : null }</div>
        <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBetOdd(0)  : setBetOdd( betodd + chipSelector.value) }} className="splitequal betoddFR"><span>ODD</span>{ betodd > 0 ? <Chip size={{w:"25px",h:"25px"}} value={betodd} /> : null }</div>
        <div onClick={()=>{ totBetAmount + chipSelector.value > user.tokens ? setBetAmtExecded(true) : removeBet ? setBet19to36(0)  : setBet19to36( bet19to36 + chipSelector.value) }} className="splitequal CornerB18FR"><span>19 TO 36</span>{ bet19to36 > 0 ? <Chip size={{w:"25px",h:"25px"}} value={bet19to36} /> : null }</div>
      </div>
      {betAmtExecded === true ? <WLpopup popopen={true} func={handleBetAmtExecded} text1={`Bet Amount Exceeded`} text2={`Please reset your bets`} text3={`Reset`} time={500} /> : null}
    {frgameOver === true ? <> <WLpopup popopen={true} win={true} func={resetAllBets} text1={`Roulette Wheel Number ${winNumber} `} text2={`You have won ${winAmount} `} text3={`${winAmount}`} time={1000} /><Preset preset="fireworks"/> </>: null }
    </div>
  );
}

export default BettingTable;
