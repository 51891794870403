import Crossfire from "react-canvas-confetti/dist/presets/crossfire";
import Fireworks from 'react-canvas-confetti/dist/presets/fireworks';
import Snow  from 'react-canvas-confetti/dist/presets/snow';
import Explosion from 'react-canvas-confetti/dist/presets/explosion';
import Pride from 'react-canvas-confetti/dist/presets/pride';
import Realistic from 'react-canvas-confetti/dist/presets/realistic';
import Vortex from 'react-canvas-confetti/dist/presets/vortex';
import Photons from 'react-canvas-confetti/dist/presets/photons';

function Example({preset}) {

    const decorateOptions = (defaultOptions) => {
        return {
          ...defaultOptions,
          colors: ['#FFFFFF','#FF0000'],
        };
      };
    switch (preset) {
        case "fireworks":
            return <Fireworks autorun={{ speed: 3 }} />;
        case "crossfire":
            return <Crossfire autorun={{ speed: 3 }} />;
        case "snow":
            return <Snow autorun={{ speed: 30 }} decorateOptions={decorateOptions} />;
        case "explosion":
            return <Explosion autorun={{ speed: 3 }} />;
        case "pride":
            return <Pride autorun={{ speed: 15 }} />;
        case "realistic":
            return <Realistic autorun={{ speed: 2 }} />;
        case "vortex":
            return <Vortex autorun={{ speed: 3 }} />;
        case "photons":
            return <Photons autorun={{ speed: 5 }} />;
        default:
            return <Fireworks autorun={{ speed: 2 }} />;
    }
    
 
}

export default Example;