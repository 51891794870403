import React from 'react'
import RouletteBetTable from './RouletteBetTable'

import { useContext } from 'react'
import RussianRouletteContext from './RussianRouletteMainContext'
import Preset from '../../components/Helper/Preset'
import WLpopup from '../../components/Helper/WLpopup'
function GamePad() {
  const {spin,winNumber,winAmount,gameOver,resetAllBets, betAmtExecded,handleBetAmtExecded,  } = useContext(RussianRouletteContext);

  return (
    <div  className= 'gameDesign' >
    <RouletteBetTable/>
    {betAmtExecded === true ? <WLpopup popopen={true} func={handleBetAmtExecded} text1={`Bet Amount Exceeded`} text2={`Please reset your bets`} text3={`Reset`} time={500} /> : null}
    {gameOver === true && spin === false  ? <> <WLpopup popopen={true} win={true} func={resetAllBets} text1={`Roulette Wheel Number ${winNumber} `} text2={`You have won ${winAmount} `} text3={`${winAmount}`} time={1000} /><Preset preset="fireworks"/> </>
    : null } 
    </div>
    
  )
}

export default GamePad