import React,{useContext,useEffect,useState} from 'react'
import Chip from '../../components/Helper/Chip'
import './BettingPad.css'
import SpinWheel from './SpinWheel'
import UserContext from '../Login/UserContext';
import RussianRouletteContext from './RussianRouletteMainContext';
import rrback from './img/try4.png'
import rrtitle from './img/titleRR.png'

function BettingPad() {
  console.log(useContext(RussianRouletteContext))
  const {user} = useContext(UserContext)
  const {spin,chipSelector,totbetAmount,handlechipSelector,resetAllBets,setBets,setSpin,numbers,numDetailsArray, betAmtExecded} = useContext(RussianRouletteContext)
  const userId = user.userId;
  
  console.log(totbetAmount,chipSelector)
  return (
    <div className={betAmtExecded === true ? 'bettingpadDesign disableclick' : 'bettingpadDesign' }>
      <div className="RRSidepannel1"  >
        <div className="RRname flex-set" style={{backgroundImage:`url(${rrtitle})`}}><h1 className='golden-base golden1' style={{  paddingLeft:'30px',fontSize:'2.7em'}}>Russian Roulette</h1></div>
        <div className="bettotamount flex-set">
          <label className='golden-base golden2'>User Tokens</label>
        <span className='userAmount flex-set' id="rrut" style={{backgroundImage:`url(${rrback})`}}>{user.tokens}</span>
        
      </div>
        <div className='RRchipset'>
        <div onClick={(e) => {handlechipSelector(1)} } className={ chipSelector.value === 1 ? 'selectedchip flex-set':'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={1} /></div>
        <div onClick={(e) => {handlechipSelector(5)} } className={chipSelector.value === 5 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={5} /></div>
        <div onClick={(e) => {handlechipSelector(10)} } className={chipSelector.value === 10 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={10} /></div>
        <div onClick={(e) => {handlechipSelector(50)} } className={chipSelector.value === 50 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={50} /></div>
        <div onClick={(e) => {handlechipSelector(100)} } className={chipSelector.value === 100 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={100} /></div>
        <div onClick={(e) => {handlechipSelector(500)} } className={chipSelector.value === 500 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={500} /></div>
        <div onClick={(e) => {handlechipSelector(1000)} } className={chipSelector.value === 1000 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={1000} /></div>
        <div onClick={(e) => {handlechipSelector(2000)} } className={chipSelector.value === 2000 ? 'selectedchip flex-set' : 'chipselectordiv flex-set'} ><Chip size={{w:"30px",h:"30px"}} value={2000} /></div>
        </div>
        
      </div>
      <SpinWheel/>
      
      <div className={ spin || totbetAmount === 0 ? "betbuttonsdiv disableclick":"betbuttonsdiv"}>
          
          <div className="RRlast10num" style={{backgroundImage:`url(${rrtitle})`,}}>
            
            <ul className='flex-set' 
            style={{
              transform:'scaleX(-1)',color:'white',
              listStyle:'none',gap:"10px",
              fontSize: '1.5em',
              fontWeight: '900',
              width: '70%',
              display:'flex',
              flexWrap: 'wrap',
              marginLeft:'30px'
              }}>
            { 
            numbers && numbers.map((id,num) => 
             (<>
             <li id={num} style={{ color :numDetailsArray.find( o => o.val === id).color}}>{id}</li>
            
             </> 
            ))
    
            
            }
            </ul>
          </div>
          <div className='betbuttonscomm '>
          <label className='golden-base golden2'>Total Bet</label>
          <span className='betAmount flex-set' id='rrbt' style={{backgroundImage:`url(${rrback})`}}>{totbetAmount}</span>
          
          </div>
          
          <div className='betbbuttonsdiv flex-set'>
          <div  className="betbuttons divred">
            <button  onClick={()=>{
              setSpin(true)
              setBets(userId)
            }}>
            Spin
            </button>
          </div>
          <div onClick={()=>{resetAllBets()}} className="betbuttons divgreen">
              Reset
          </div>
          </div>
          
        </div>
    </div>
  )
}

export default BettingPad